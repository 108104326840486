/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="home">
        <div class="page-content">
            <div class="content-wrapper content-wrapper-index-page">
                <div class="img-index">
                    <img data-src="./static/mys_logo.png" class="lazy" />
                </div>

                <div class="button-index slideUp">
                    <a href="/booking/">${text_obj.value.booking_next}</a>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = 'c0bbe67600';
export default framework7Component;