/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page about-page">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="svg_icon">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.23989 0.333496H16.7716C20.8899 0.333496 23.6666 2.983 23.6666 6.93683V17.0518C23.6666 21.0068 20.8899 23.6668 16.7716 23.6668H7.23989C3.10989 23.6668 0.333221 21.0068 0.333221 17.0518V6.93683C0.333221 2.983 3.10989 0.333496 7.23989 0.333496ZM9.35155 12.8752H16.7599C17.2499 12.8752 17.6349 12.4785 17.6349 12.0002C17.6349 11.5102 17.2499 11.1252 16.7599 11.1252H9.35155L12.2449 8.2435C12.4082 8.08016 12.5016 7.84683 12.5016 7.62516C12.5016 7.40466 12.4082 7.18183 12.2449 7.00683C11.9066 6.6685 11.3466 6.6685 11.0082 7.00683L6.60989 11.3818C6.28322 11.7085 6.28322 12.2918 6.60989 12.6185L11.0082 16.9935C11.3466 17.3318 11.9066 17.3318 12.2449 16.9935C12.5832 16.6435 12.5832 16.0952 12.2449 15.7452L9.35155 12.8752Z"
                                fill="#ED6947" />
                        </svg>
                    </a>
                </div>
                <div class="title">
                    ${$store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.name
                    ?
                    <div>{$store.getters.language.value == 'ru' ?
                        $store.getters.tabs_obj.value.by_code.information_title.information_services.items_arr.information_wi_fi.name
                        :
                        $store.getters.tabs_obj.value.by_code.information_title.information_services.items_arr.information_wi_fi.name_en}
                    </div>
                    :
                    <div>{text_obj.value.wi_fi_title}</div>
                    }
                </div>
            </div>
        </div>
        <div class="page-content">
            ${$store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.html
            ?
            <div class="display-flex justify-content-center align-items-center height-100">
                <div class="block text-align-center">
                    <div innerHTML={$store.getters.language.value=='ru' ?
                        $store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.html
                        :
                        $store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.html_en}>
                    </div>

                </div>
            </div>
            :
            <div class="display-flex justify-content-center align-items-center height-100">

                <div class="block display-flex">
                    <div class="wifi-block">
                        <div class="title">{text_obj.value.wi_fi_login}</div>
                        <div class="description">
                            {$store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.name
                            ?
                            <span>{$store.getters.tabs_obj.value.by_code.information_title.information_services.items_arr.information_wi_fi.wi_fi_login}</span>
                            :
                            <span>MYS Hotel</span>
                            }
                        </div>
                    </div>
                    <div class="wifi-block">
                        <div class="title">{text_obj.value.wi_fi_password}</div>
                        <div class="description">
                            {$store.getters?.tabs_obj?.value.by_code?.information_title?.information_services?.items_arr?.information_wi_fi?.name
                            ?
                            <span>{$store.getters.tabs_obj.value.by_code.information_title.information_services.items_arr.information_wi_fi.wi_fi_password}</span>
                            :
                            <span>12340987</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '02c079725d';
export default framework7Component;