/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#" class="link back">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">${text_obj.value.back_404}</span>
                    </a>
                </div>
                <div class="title">${text_obj.value.not_found_404}</div>
            </div>
        </div>
        <div class="page-content">
            <div class="block block-strong">
                <p>${text_obj.value.sorry_404}</p>
                <p>${text_obj.value.request_not_found_404}</p>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '36c50b0e62';
export default framework7Component;