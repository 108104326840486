import { createStore } from 'framework7';
import './localization';

const language = (localStorage.getItem('language') || window.navigator.language || 'en_US').replace(/-/g, '_').substring(0, 2);
const root = 'https://dc.mys-hotel.ru/';

const store = createStore({
    state: {
        root,

        food: [],
        food_basket: [],
        food_total_price: 0,
        food_is_open: null,
        min_order_amount: null,

        clothes: [],
        clothes_basket: [],
        clothes_total_price: 0,

        room_list: null,
        room_detail: null,
        content_detail: null,
        roomNumber: null,

        order_sent_success: null,
        isLoading: false,
        language: (localStorage.getItem('language') || window.navigator.language || 'en_US').replace(/-/g, '_').substring(0, 2),
        text_obj: window.localization[language] || window.localization['en'],
        tabs_obj: [],
        hotel_tabs_obj: [],
        information_tabs: [],
        remote_server_name: root,

        services: {
            light_on_off: false,
            shine: false,
            tv_1: false,
            tv_2: false,
            humidifier: {
                value: 0,
                mode: false,
            },
            coffee_machine: false,
            tea_machine: false,
            floor_lamp: false,
            meeting_room_1: false,
            meeting_room_2: false,
            hall: false,
            hall_2: false,
            reception: false,
            zig_zag: false,
            room_1: false,
            room_2: false,
            error_text: false,
            warm_floor: false,
            conditioner: false,
            light_main: false,
            light_illumination: false,
            light_bathroom: false,
            curtains_right: false,
            curtains_left: false,
        },
        servicesFetch: false,
        allServicesFetch: false,
        programming_fields: {
            p0: 100,
            p1: 50,
            p2: 100,
            p3: 200,
            d1: 3,
            d2: 10,
            d3: 20,
            chat_m0: 3000, // таймаут прелоадера заявки в добавлении быстрых задач кайзен
            chat_scrollHeight: 300, // отступ сверху в чатах
            chat_timeoutGetMsgs: 3000, // таймаут получения сообщений в чатах
            chat_timeoutSendMsgs: 2000, // таймаут отправления сообщений в чатах
            chat_countGetMsgs: 1500, // количество обращений к серваку в чатах после которого снижается частота обращений в чатах
            chat_wsTimeout: 30000, // websocket timeout
            chat_wsSendMessageTimeout: 1000, // websocket timeout SendMessage
            chat_getUnreadMsgTimeout: 5000, // таймаут запроса за непрочитанными сообщениями
            chat_getUnreadMsgTimeoutStart: 20000,
            show_phone_login: true,
            show_code_login: true,
            chat_bot_active: true,
            chat_camera_quality: 40,
            loyalty_json_disable: false,
            information_newtel_server: 'https://new-call.me/', // адрес для звонков
            information_newtel_call_logo:
                '<svg id="_Слой_1" viewBox="0 0 79.88 79.88"><defs><style>.cls-1{fill:#8e8f6d;}</style></defs><path class="cls-1" d="m39.94,0C17.92,0,0,17.92,0,39.94s17.92,39.94,39.94,39.94,39.94-17.92,39.94-39.94S61.96,0,39.94,0Zm0,77.86c-20.91,0-37.92-17.01-37.92-37.92S19.03,2.02,39.94,2.02s37.92,17.01,37.92,37.92-17.01,37.92-37.92,37.92Z"/><path class="cls-1" d="m68.24,51.58l-1.03-5.3-11.66-2.51-2.34,2.05-1.22,2.95c-18.49-2.58-24.41-16.44-24.41-16.44l2.27-2.24,1.02-2.94-6.71-9.86-5.37,1.05c-1.28.25-2.44.92-3.28,1.91-1.1,1.3-2.45,3.09-2.39,4.09.3,5.73,10.81,15.89,16.63,21.09,2.49,2.23,5.24,4.17,8.19,5.74,12.46,6.66,20.28,7.99,24.1,8.14,1.65.06,3.2-.75,4.12-2.12l1.36-2.02c.71-1.06.97-2.35.73-3.6Z"/></svg>',
            room_management_bkng_timeout: 30000,
            auth_by_phone_cancel_timeout: 60000,
            vibro_time: 70,
        },

        phone_auth_fields: {
            f1: '+7 (999) 999',
            send_code: false,
            send_in_progress: false,
            error_text: false,
            user_phone: localStorage.getItem('user_phone'),
            from_server: false,
        },
        phone_auth_error: false,
        room_fields: [],
        booking_obj: false,

        user: { name: 'Guest' },
        user_update: {
            update_in_progress: false,
            is_updated: false,
        },

        show_info_notification: true,
        show_info_notification_information_page: true,
        unread_msg: null,
        auth_phone_number: null,
        user_phone_number: null,
        user_authorized: false,
        authorized_code: null,
        user_info_fetching: true,

        booking_id: '',
        uuid: '',

        //coworking
        coworking: {
            is_coworking: false,
            tariff: '',
            days_left: 0,
        },
        coworking_tariffs: [],
        coworking_tariffs_fetching: false,
        coworking_tariff: null,
        coworking_pay_list: [],
        coworking_auth_type: 1,

        // shop
        shop: [],
        shop_fetching: false,
        shop_item_detail: null,

        booking_link: '#',
        politika_link: '#',
    },
    getters: {
        root: ({ state }) => state.root,
        food: ({ state }) => state.food,
        food_basket: ({ state }) => state.food_basket,
        food_total_price: ({ state }) => state.food_total_price,
        clothes: ({ state }) => state.clothes,
        clothes_basket: ({ state }) => state.clothes_basket,
        clothes_total_price: ({ state }) => state.clothes_total_price,
        order_sent_success: ({ state }) => state.order_sent_success,
        isLoading: ({ state }) => state.isLoading,
        food_is_open: ({ state }) => state.food_is_open,
        room_detail: ({ state }) => state.room_detail,
        content_detail: ({ state }) => state.content_detail,
        room_list: ({ state }) => state.room_list,
        text_obj: ({ state }) => state.text_obj,
        tabs_obj: ({ state }) => state.tabs_obj,
        hotel_tabs_obj: ({ state }) => state.hotel_tabs_obj,
        remote_server_name: ({ state }) => state.remote_server_name,
        language: ({ state }) => state.language,
        services: ({ state }) => state.services,
        servicesFetch: ({ state }) => state.servicesFetch,
        allServicesFetch: ({ state }) => state.allServicesFetch,
        programming_fields: ({ state }) => state.programming_fields,
        phone_auth_fields: ({ state }) => state.phone_auth_fields,
        room_fields: ({ state }) => state.room_fields,
        booking_obj: ({ state }) => state.booking_obj,
        information_tabs: ({ state }) => state.information_tabs,
        user: ({ state }) => state.user,
        user_update: ({ state }) => state.user_update,
        show_info_notification_getter: ({ state }) => state.show_info_notification,
        show_info_notification_information_page: ({ state }) => state.show_info_notification_information_page,
        get_unread_msg: ({ state }) => state.unread_msg,
        get_auth_phone_number: ({ state }) => state.auth_phone_number,
        get_user_phone_number: ({ state }) => state.user_phone_number,
        get_user_authorized: ({ state }) => state.authorized,
        booking_id: ({ state }) => state.booking_id,
        uuid: ({ state }) => state.uuid,
        authorized_code: ({ state }) => state.authorized_code,
        min_order_amount: ({ state }) => state.min_order_amount,
        roomNumber: ({ state }) => state.roomNumber,
        auth_phone_error: ({ state }) => state.phone_auth_error,
        user_info_fetching: ({ state }) => state.user_info_fetching,

        //Coworking
        coworking: ({ state }) => state.coworking,
        is_coworking: ({ state }) => state.coworking.is_coworking,
        coworking_tariffs: ({ state }) => state.coworking_tariffs,
        coworking_tariffs_fetching: ({ state }) => state.coworking_tariffs_fetching,
        coworking_tariff: ({ state }) => state.coworking_tariff,
        coworking_pay_list: ({ state }) => state.coworking_pay_list,
        coworking_auth_type: ({ state }) => state.coworking_auth_type,

        // shop
        shop: ({ state }) => state.shop,
        shop_fetching: ({ state }) => state.shop_fetching,
        shop_item_detail: ({ state }) => state.shop_item_detail,
    },

    actions: {
        setService: async ({ state }, item) => {
            state.servicesFetch = item.type;
            let adr = 'onoffswitcher';
            let dim_on = 1;
            if (!item?.value) {
                dim_on = 0;
            }
            let raw_item = JSON.stringify(item);

            const formData = new FormData();
            formData.append('type', item.type);
            formData.append('dim_on', dim_on);
            formData.append('raw_item', raw_item);
            formData.append('booking_id', localStorage.getItem('booking_id'));
            formData.append('uuid', localStorage.getItem('uuid'));
            if (item?.trueColor) {
                formData.append('trueColor', item.trueColor);
            }

            try {
                const response = await fetch(`${root}?r=api/` + adr, { method: 'POST', body: formData });
                if (response.status === 200) {
                    const result = await response.json();
                    try {
                        const res = JSON.parse(result.response);
                        if (res.status === 'ok') {
                            if (item.type === 'warm_floor' && item.value) {
                                state.services = { ...state.services, [item.type]: 22 };
                                return true;
                            } else {
                                state.services = { ...state.services, [item.type]: item.value };
                                return true;
                            }
                        } else if (res.error_message) {
                            state.services = { ...state.services, error_text: v.error_message };
                            return true;
                        } else {
                            return false;
                        }
                    } catch (e) {
                        return false;
                    }
                }
            } catch (e) {
                return false;
            } finally {
                state.servicesFetch = false;
            }
        },

        setOrderSentSuccess({ state }) {
            state.order_sent_success = false;
        },

        addFoodToBasket({ state }, item) {
            let found = false;

            const result = state.food_basket
                .map(food_item => {
                    if (food_item.id === +item.id) {
                        found = true;

                        let count = food_item.count;

                        if (item.count === 'plus') {
                            count++;
                        } else if (item.count === 'minus') {
                            count--;
                        } else if (item.count === 'delete') {
                            return {};
                        }

                        if (count > 0) {
                            return { ...food_item, count };
                        }

                        return {};
                    } else {
                        return food_item;
                    }
                })
                .filter(food_item => food_item.hasOwnProperty('id'));

            if (!found) {
                result.push({
                    id: +item.id,
                    name: item.name,
                    price: +item.price,
                    img_path: item.img_path,
                    weight: +item.weight,
                    mass: item.mass,
                    count: 1,
                });
            }

            state.food_basket = result;

            let price = 0;
            result.forEach(item => (price += item.count * item.price));
            state.food_total_price = price;
        },

        async refreshLanguage({ state }) {
            state.language = (localStorage.getItem('language') || window.navigator.language || 'en_US').replace(/-/g, '_').substring(0, 2);
            state.text_obj = window.localization[state.language] || window.localization['en'];
            this.getLocalizationList({ state });
        },

        async getFood({ state }, item = false) {
            const formData = new FormData();

            //const language = (localStorage.getItem('language') || (window.navigator.language || 'en_US')).replace(/-/g, "_").substring(0,2);
            const language = state.language;

            formData.append('lang', language);
            if (state?.hotel_tabs_obj?.hotel_id) {
                formData.append('hotel_id', state?.hotel_tabs_obj?.hotel_id);
            }
            if (item?.food_id && item?.food_id !== 1) {
                formData.append('food_id', item?.food_id);
            }

            await fetch(`${root}?r=api/getfoodlistbycategory`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(list => {
                    state.food = list;
                });
        },

        clearShopBasket: ({ state }) => {
            state.clothes_basket = [];
        },

        addClothesToBasket({ state }, item) {
            let found = false;

            const result = state.clothes_basket
                .map(basket_item => {
                    if (basket_item.id === +item.id) {
                        found = true;

                        let count = basket_item.count;

                        if (item.count === 'plus') {
                            count++;
                        } else if (item.count === 'minus') {
                            count--;
                        } else if (item.count === 'delete') {
                            return {};
                        }

                        if (count > 0) {
                            return { ...basket_item, count };
                        }

                        return {};
                    } else {
                        return basket_item;
                    }
                })
                .filter(basket_item => basket_item.hasOwnProperty('id'));

            if (!found) {
                result.push({
                    id: +item.id,
                    name: item.name,
                    price: +item.price,
                    img_path: item.img_path,
                    size: item.size,
                    color: item.color,
                    count: 1,
                    name_en: item.name_en,
                });
            }

            state.clothes_basket = result;
            let price = 0;
            result.forEach(item => (price += item.count * item.price));
            state.clothes_total_price = price;
        },

        async getClothes({ state }) {
            const formData = new FormData();

            //const language = (localStorage.getItem('language') || (window.navigator.language || 'en_US')).replace(/-/g, "_").substring(0,2);
            const language = state.language;

            formData.append('lang', language);
            formData.append('booking_id', localStorage.getItem('booking_id'));

            await fetch(`${root}?r=api/getclotheslist`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(list => {
                    state.clothes = list;
                });
        },

        async isOpenedBar({ state }) {
            const formData = new FormData();
            formData.append('booking_id', localStorage.getItem('booking_id'));

            await fetch(`${root}?r=api/isbarworking`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(result => {
                    state.food_is_open = result;
                });
        },

        async sendOrder({ state }, item) {
            if (!state.isLoading) {
                state.isLoading = true;
                const formData = new FormData();

                let orderList = null;
                if (item.type === 1) {
                    orderList = state.food_basket;
                } else if (item.type === 2) {
                    orderList = state.clothes_basket;
                }

                const order =
                    orderList &&
                    orderList.map(order_item => {
                        return { i: order_item.id, count: order_item.count };
                    });

                formData.append('type', item.type);
                formData.append('app_short_name', 'DC');
                formData.append('booking_id', localStorage.getItem('booking_id'));
                formData.append('order', JSON.stringify(order));

                await fetch(`${root}?r=api/addordermarket`, { method: 'POST', body: formData })
                    .then(res => res.json())
                    .then(result => {
                        if (result === 'ok') {
                            if (item.type === 1) {
                                state.food_basket = [];
                                state.food_total_price = 0;
                            } else if (item.type === 2) {
                                state.clothes_basket = [];
                                state.clothes_total_price = 0;
                            }

                            state.order_sent_success = true;
                            state.isLoading = false;
                        }
                    });
            }
        },

        async getRoomDetail({ state }, item) {
            if (state?.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[item.roomId]) {
                state.room_detail = { name: 'sdsd' };
                let obj = {
                    name: state.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[item.roomId].name,
                    imgLink: state.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[item.roomId].files,
                    content:
                        state.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[item.roomId]
                            .detail_description,
                };
                if (state.language === 'en') {
                    obj.name =
                        state.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[item.roomId].name_en;
                    obj.content =
                        state.tabs_obj.by_code.information_title.information_information.items_arr.information_hotel_rooms.items_arr[
                            item.roomId
                        ].detail_description_en;
                }
                state.room_detail = obj;
            } else {
                const formData = new FormData();

                //const language = (localStorage.getItem('language') || (window.navigator.language || 'en_US')).replace(/-/g, "_").substring(0,2);
                const language = state.language;

                formData.append('lang', language);
                formData.append('id', item.roomId);

                // await fetch('https://dc.mys-hotel.ru/data/API/information.php', { method: 'POST', body: formData })
                //     .then(res => res.json())
                //     .then(result => {
                //         state.room_detail = result;
                //     });
            }
        },

        async getContentDetail({ state }, item) {
            const formData = new FormData();
            const language = state.language;

            formData.append('lang', language);
            formData.append('id', item.contentId);

            await fetch(`${root}?r=api/getcontent`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(result => {
                    state.content_detail = { ...result, json: result.json ? JSON.parse(result.json) : null };
                });
        },

        clearContentDetail({ state }) {
            state.content_detail = null;
        },

        async getRoomList({ state }) {
            const formData = new FormData();

            //const language = (localStorage.getItem('language') || (window.navigator.language || 'en_US')).replace(/-/g, "_").substring(0,2);
            const language = state.language;

            formData.append('lang', language);
            formData.append('id_array', JSON.stringify(['room_1', 'room_2', 'room_3', 'room_4']));

            // await fetch('https://dc.mys-hotel.ru/data/API/information.php', { method: 'POST', body: formData })
            //     .then(res => res.json())
            //     .then(result => {
            //         state.room_list = result;
            //     });
        },

        async getHotelTabsObj({ state }) {
            state.user_info_fetching = true;
            const formData = new FormData();
            formData.append('booking_id', localStorage.getItem('booking_id'));
            try {
                await fetch(`${root}?r=api/getapptabsbybookingid`, { method: 'POST', body: formData })
                    .then(res => res.json())
                    .then(result => {
                        state.hotel_tabs_obj = result;
                        if (result?.programming_fields) {
                            for (const [key, value] of Object.entries(result.programming_fields)) {
                                state.programming_fields = { ...state.programming_fields, [key]: value };
                            }
                        }
                    });
            } catch (e) {
                console.log('getHotelTabsObj error', e);
            } finally {
                state.user_info_fetching = false;
            }
        },

        getLocalizationList: async ({ state }) => {
            state.allServicesFetch = true;

            const formData = new FormData();
            formData.append('booking_id', localStorage.getItem('booking_id'));
            await fetch(`${root}?r=api/getlanglist`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(result => {
                    //console.log(result);
                    const resultArr = [];
                    for (const key in result.by_code) {
                        for (const key2 in result.by_code[key]) {
                            for (const key3 in result.by_code[key][key2].items_arr) {
                                resultArr.push(result.by_code[key][key2].items_arr[key3]);
                            }
                        }
                    }
                    state.min_order_amount = result.food_min_price;
                    state.information_tabs = resultArr;
                    state.tabs_obj = result;

                    if (result?.localization && result.localization[state.language]) {
                        for (const key in result.localization[state.language]) {
                            //state.text_obj[key]=result.localization[state.language][key];
                            state.text_obj = { ...state.text_obj, [key]: result.localization[state.language][key] };
                        }
                    }

                    if (result?.programming_fields) {
                        for (const [key, value] of Object.entries(result.programming_fields)) {
                            state.programming_fields = { ...state.programming_fields, [key]: value };
                        }
                    }
                    if (result?.room_fields) {
                        //state.room_fields = { ...state.room_fields, room_fields: result?.room_fields };
                        //console.log(result?.room_fields);
                        /*for (const [key, value] of Object.entries(result.room_fields)) {
                            state.room_fields = { ...state.room_fields, [key]: value };
                        }*/
                        state.room_fields = result.room_fields;
                    }
                    if (result?.services) {
                        for (const [key, value] of Object.entries(result.services)) {
                            state.services = { ...state.services, [key]: value };
                        }
                    }
                    state.allServicesFetch = false;
                });
        },

        getBgLocalizationList: async ({ state }) => {
            const formData = new FormData();
            formData.append('booking_id', localStorage.getItem('booking_id'));
            await fetch(`${root}?r=api/getlanglist`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(result => {
                    const resultArr = [];
                    for (const key in result.by_code) {
                        for (const key2 in result.by_code[key]) {
                            for (const key3 in result.by_code[key][key2].items_arr) {
                                resultArr.push(result.by_code[key][key2].items_arr[key3]);
                            }
                        }
                    }
                    state.min_order_amount = result.food_min_price;
                    state.information_tabs = resultArr;
                    state.tabs_obj = result;

                    if (result?.localization && result.localization[state.language]) {
                        for (const key in result.localization[state.language]) {
                            state.text_obj = { ...state.text_obj, [key]: result.localization[state.language][key] };
                        }
                    }

                    if (result?.programming_fields) {
                        for (const [key, value] of Object.entries(result.programming_fields)) {
                            state.programming_fields = { ...state.programming_fields, [key]: value };
                        }
                    }
                    if (result?.room_fields) {
                        state.room_fields = result.room_fields;
                    }
                    if (result?.services) {
                        for (const [key, value] of Object.entries(result.services)) {
                            state.services = { ...state.services, [key]: value };
                        }
                    }
                });
        },

        getRemoteServerName: async ({ state }) => {
            await fetch(`${root}?r=api/getservername`, { method: 'GET' })
                .then(res => res.json())
                .then(list => {
                    state.remote_server_name = list;
                });
        },

        authByPhone: async ({ state }, item) => {
            const type = state.coworking_auth_type;

            const formData = new FormData();
            formData.append('uuid', item?.uuid);
            formData.append('phone', item?.phone);
            formData.append('lang', item?.lang);
            formData.append('phone_auth_f1', item?.code);
            formData.append('type', type);

            state.phone_auth_fields = { ...state.phone_auth_fields, send_in_progress: true };
            state.phone_auth_fields = { ...state.phone_auth_fields, user_phone: item?.phone };

            localStorage.setItem('user_phone', item?.phone);

            await fetch(`${root}?r=api/authbyphone`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(list => {
                    if (list.error) {
                        state.phone_auth_error = true;
                        state.phone_auth_fields = { ...state.phone_auth_fields, error_text: list.text };
                    } else {
                        if (item?.code) {
                            if (list?.v) {
                                state.phone_auth_fields = { ...state.phone_auth_fields, from_server: list };
                            }
                        } else {
                            state.phone_auth_fields = { ...state.phone_auth_fields, send_code: true };
                        }
                    }
                    state.phone_auth_fields = { ...state.phone_auth_fields, send_in_progress: false };
                });
        },
        authByPhoneClearError({ state }) {
            state.phone_auth_fields = { ...state.phone_auth_fields, error_text: false };
            state.phone_auth_fields = { ...state.phone_auth_fields, from_server: false };
        },
        roomManagementClearError({ state }) {
            state.services = { ...state.services, error_text: false };
        },
        ClearRoom_fields({ state }) {
            state.room_fields = { ...state, room_fields: [] };
        },

        getBooking: async ({ state }, data) => {
            const formData = new FormData();
            for (const item in data) {
                formData.append(item, data[item]);
            }
            formData.append('lang', state.language);

            await fetch(`${root}?r=api/getbooking`, { method: 'POST', body: formData })
                .then(response => response.json())
                .then(res => {
                    try {
                        state.roomNumber = res.v;
                        state.booking_obj = res;
                        state.booking_id = data.booking_id;
                        state.uuid = data.uuid;

                        if (res.hasOwnProperty('is_coworking')) {
                            state.coworking = {
                                ...state.coworking,
                                is_coworking: res.is_coworking,
                                tariff: res.tariff_label,
                                tariff_id: res.tariff_id,
                                // tariff_id:null,

                                days_left: res.days_left,
                            };
                        }

                        if (res.hasOwnProperty('guest_info')) {
                            const data = res.guest_info;
                            state.user = {
                                loyalty_card_number: data.loyalty_card_number || null,
                                loyalty_disabled: data.loyalty_disabled || false,
                                loyalty_status_id: data.loyalty_status_id || null,
                                loyalty_percent_discount: `${data.percent_discont}%` || '',
                                loyalty_nights_to_next_status: data.nights_to_next_status || null,
                                loyalty_name: data.name || '',
                                loyalty_name_en: data.name_en || '',
                                loyalty_nights_count:
                                    Array.isArray(data.nights_count) && data.nights_count.length > 0 ? data.nights_count.reduce((a, b) => a + b.days, 0) : 0,
                                name: data.Name || '',
                                phone: data.clean_value ? `+${data.clean_value}` : '',
                                id: data.id || null,
                                guest_id: data.guest_id || null,
                                photo_link: Array.isArray(data.files) && data.files.length > 0 ? data.files[0].link : '',
                                label: res.label || null,
                                residence_history:
                                    Array.isArray(data.nights_count) && data.nights_count.length > 0
                                        ? data.nights_count.map(el => {
                                              return el.days_detail.map(ell => {
                                                  return {
                                                      date_from: new Date(ell.date_from).toLocaleString('ru', {
                                                          month: 'numeric',
                                                          day: 'numeric',
                                                          year: 'numeric',
                                                      }),
                                                      date_to: new Date(ell.date_to).toLocaleString('ru', {
                                                          month: 'numeric',
                                                          day: 'numeric',
                                                          year: 'numeric',
                                                      }),
                                                      room_num: { hotel_name_ru: ell.hotel_name_ru, hotel_name_en: ell.hotel_name_en },
                                                  };
                                              });
                                          })
                                        : null,
                            };
                        }
                    } catch (e) {
                        console.log('Error in api/getbooking: ', e);
                    }
                });

            state.user_update = { ...state.user_update, is_updated: false };
        },

        setUser({ state }, data) {
            state.user = {
                loyalty_card_number: data.loyalty_card_number || null,
                loyalty_disabled: data.loyalty_disabled || false,
                loyalty_status_id: data.loyalty_status_id || null,
                loyalty_percent_discount: `${data.percent_discont}%` || '',
                loyalty_nights_to_next_status: data.nights_to_next_status || null,
                loyalty_name: data.name || '',
                loyalty_name_en: data.name_en || '',
                loyalty_nights_count: Array.isArray(data.nights_count) && data.nights_count.length > 0 ? data.nights_count.reduce((a, b) => a + b.days, 0) : 0,
                name: data.Name || '',
                phone: data.clean_value ? `+${data.clean_value}` : '',
                id: data.id || null,
                guest_id: data.guest_id || null,
                photo_link: Array.isArray(data.files) && data.files.length > 0 ? data.files[0].link : '',
                label: data.label || null,
                residence_history:
                    Array.isArray(data.nights_count) && data.nights_count.length > 0
                        ? data.nights_count.map(el => {
                              return el.days_detail.map(ell => {
                                  return {
                                      date_from: new Date(ell.date_from).toLocaleString('ru', {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric',
                                      }),
                                      date_to: new Date(ell.date_to).toLocaleString('ru', {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric',
                                      }),
                                      room_num: ell.hotel_name_for_app,
                                  };
                              });
                          })
                        : null,
            };
        },

        updateUser: async ({ state }, data) => {
            state.user_update = { ...state.user_update, update_in_progress: true };
            const formData = new FormData();
            for (const item in data) {
                formData.append(item, data[item]);
            }

            await fetch(`${root}?r=api/changeguest`, { method: 'POST', body: formData })
                .then(response => response.json())
                .then(res => {
                    try {
                        if (res.hasOwnProperty('result') && res.result === 'guest updated') {
                            state.user_update = { ...state.user_update, is_updated: true };
                        }
                    } catch (e) {
                        console.log('Error in api/changeguest: ', e);
                    } finally {
                        state.user_update = { ...state.user_update, update_in_progress: false };
                    }
                });
        },

        closeInfoNotification({ state }) {
            state.show_info_notification = false;
        },
        closeInfoNotificationInformationPage({ state }) {
            state.show_info_notification_information_page = false;
        },

        checkMobileOrNot: async ({ state }, data) => {
            const formData = new FormData();
            for (const item in data) {
                formData.append(item, data[item]);
            }
            await fetch(`${root}?r=api/weblogin`, { method: 'POST', body: formData });
        },

        sendTaskReview: async ({ state }, data) => {
            const formData = new FormData();
            for (const item in data) {
                formData.append(item, data[item]);
            }
            await fetch(`${root}?r=api/ratingkaizen`, { method: 'POST', body: formData });
        },

        setUnreadMsg: ({ state }, data) => {
            state.unread_msg = data;
        },

        getUnreadMsg: async ({ state }) => {
            const formData = new FormData();
            formData.append('booking_id', localStorage.getItem('booking_id'));

            await fetch(`${root}?r=api/unreadmsg`, { method: 'POST', body: formData })
                .then(response => response.json())
                .then(res => {
                    return (state.unread_msg = res.count);
                });
        },

        setAuthPhoneNumber: ({ state }, data) => {
            state.auth_phone_number = data;
        },

        setAuthCode: ({ state }, data) => {
            state.authorized_code = data;
        },

        getAuthPhoneNumber: async ({ state }) => {
            const formData = new FormData();
            const type = state.coworking_auth_type;
            formData.append('secret', 'jn=pRQUG^HO2qaO(5roe');
            formData.append('type', type);

            const response = await fetch(`${root}?r=api/phonestart`, { method: 'POST', body: formData });
            const res = await response.json();
            if (res.code === 503) {
                return res.code;
            }
            state.auth_phone_number = res.phone_to;
        },

        cancelAuthPhoneNumber: async ({ state }, data) => {
            const formData = new FormData();
            formData.append('phone_to', data);
            try {
                const response = await fetch(`${root}?r=api/phonecancel`, { method: 'POST', body: formData });
                if (response.status === 200) {
                    const res = await response.json();
                    if (res.code === '200') {
                        state.auth_phone_number = null;
                    } else {
                        console.log('fetch CANCEL phone number error');
                    }
                }
            } catch (e) {
                console.log('fetch CANCEL phone number error');
            }
        },

        checkAuthPhoneNumber: async ({ state }, data) => {
            const formData = new FormData();
            formData.append('phone_to', data);
            try {
                const response = await fetch(`${root}?r=api/phonecheck`, { method: 'POST', body: formData });
                if (response.status === 200) {
                    const res = await response.json();

                    if (res.authorized === '1') {
                        state.user_authorized = true;
                        state.user_phone_number = res.phone;
                        await store.dispatch('authByPhone', {
                            uuid: localStorage.getItem('uuid'),
                            phone: res.phone,
                            code: res.code,
                        });
                    }
                    if (res.authorized === '2') {
                        state.user_phone_number = res.phone;
                        state.authorized_code = res.authorized;
                        state.auth_phone_number = null;
                    }
                }
            } catch (e) {
                console.log('fetch CHECK phone number error');
            }
        },

        getCoworkingTariffs: async ({ state }) => {
            try {
                if (!state.coworking_tariffs.length && state.booking_obj.hotel_id) {
                    state.coworking_tariffs_fetching = true;

                    const formData = new FormData();
                    formData.append('hotel_id', state.booking_obj.hotel_id);
                    formData.append('lang', state.language);

                    const response = await fetch(`${root}?r=helper/tariflist`, { method: 'POST', body: formData });
                    if (response.status === 200) {
                        const res = await response.json();

                        state.coworking_tariffs = res.map(tariff => {
                            let period = '';
                            if (tariff.days_count === 1) {
                                period = 'день';
                            } else if (tariff.days_count === 30) {
                                period = 'месяц';
                            }

                            return {
                                name: tariff.NAME,
                                description: tariff.additional,
                                price: tariff.PRICE,
                                id: tariff.ID,
                                services: tariff.SERVICES,
                                night_discount: tariff.night_discount,
                                period,
                            };
                        });
                    }
                }
            } catch (e) {
                console.log('getCoworkingTariffs error: ', e);
            } finally {
                state.coworking_tariffs_fetching = false;
            }
        },

        getCoworkingTariff: async ({ state }, id) => {
            try {
                if (!state.coworking_tariffs.length) {
                    await store.dispatch('getCoworkingTariffs');
                }

                if (state.coworking_tariffs.length && id) {
                    const tariff = state.coworking_tariffs.find(tariff => tariff.id === id);

                    if (tariff) {
                        state.coworking_tariff = tariff;
                    }
                }
            } catch (e) {
                console.log('getCoworkingTariff error: ', e);
            }
        },

        getCoworkingTariffPayList: async ({ state }) => {
            try {
                if (state.booking_id) {
                    const formData = new FormData();
                    formData.append('booking_id', state.booking_id);

                    const response = await fetch(`${root}?r=api/getpaylist`, { method: 'POST', body: formData });
                    if (response.status === 200) {
                        state.coworking_pay_list = await response.json();
                    }
                }
            } catch (e) {
                console.log('getCoworkingTariffPayList error: ', e);
            }
        },

        setCoworkingAuthType: ({ state }, data) => {
            state.coworking_auth_type = data;
        },
        setAuthPhoneError: ({ state }, data) => {
            state.phone_auth_error = data;
        },

        getShop: async ({ state }) => {
            state.shop_fetching = true;
            try {
                const formData = new FormData();
                formData.append('booking_id', localStorage.getItem('booking_id'));

                const response = await fetch(`${root}?r=shop/getshop`, { method: 'POST', body: formData });
                if (response.status === 200) {
                    const result = await response.json();
                    state.shop = result;
                }
            } catch (e) {
                console.log('getShop error: ', e);
            } finally {
                state.shop_fetching = false;
            }
        },
        getShopItemDetail: ({ state }, data) => {
            state.shop_fetching = true;

            try {
                state.shop_item_detail = state.shop.find(el => el.id === data.categoryId).items.find(el => el.id === data.shopItemId);
            } catch (e) {
                console.log('getShopItemDetail error: ', e);
            } finally {
                state.shop_fetching = false;
            }
        },

        sendShopOrder: async ({ state }, data) => {
            try {
                const formData = new FormData();
                formData.append('booking_id', localStorage.getItem('booking_id'));
                formData.append('order', JSON.stringify(data));

                const response = await fetch(`${root}?r=shop/payment`, { method: 'POST', body: formData });
                if (response.status === 200) {
                    const result = await response.json();
                    return result.link;
                }
            } catch (e) {
                console.log('sendShopOrder error: ', e);
            }
        },
    },
});
export default store;
