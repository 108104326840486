/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  var services = $store.getters.services;
  var text_obj = $store.getters.text_obj;
  var changeService = function changeService(type, value) {
    $store.dispatch('setService', {
      type: type,
      value: value
    });
  };
  $on('pageInit', function () {
    $$("#humidifier").roundSlider({
      sliderType: "min-range",
      value: services.value.humidifier.value,
      svgMode: true,
      radius: 125,
      width: 20,
      rangeColor: "#239D58",
      pathColor: '#F2F2F2',
      borderColor: '#e2e2e2',
      tooltipFormat: function tooltipFormat(v) {
        return v.value + '%';
      },
      change: function change(v) {
        return changeService('humidifier', {
          value: v.value,
          mode: services.value.humidifier.mode || 'Авто'
        });
      }
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-toolbar">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7903 4.3871L10.7071 4.29289C10.3466 3.93241 9.77939 3.90468 9.3871 4.2097L9.29289 4.29289L2.29289 11.2929L2.2515 11.3369L2.19633 11.4047L2.12467 11.5159L2.07123 11.6287L2.03585 11.734L2.00691 11.8819L2 12L2.00279 12.0752L2.02024 12.2007L2.04974 12.3121L2.09367 12.4232L2.146 12.5207L2.21969 12.6254L2.29289 12.7071L9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071C11.0676 19.3466 11.0953 18.7794 10.7903 18.3871L10.7071 18.2929L5.416 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H5.414L10.7071 5.70711C11.0676 5.34662 11.0953 4.77939 10.7903 4.3871L10.7071 4.29289L10.7903 4.3871Z" fill="black"/>
                        </svg>
                    </a>
                </div>
                <div class="title">${text_obj.value.my_room_humidifier}</div>
            </div>
        </div>
        <div class="page-content">
            <div class="block">
                <div class="humidifier-block">
                    <div id="humidifier" class="humidifier-slider"></div>

                    <div class="info">
                        Режим: ${services.value.humidifier.mode || 'Не выбран'}
                    </div>

                    <div class="buttons">
                        <div class="item">
                            <button
                                    type="button"
                                    @click=${()=>changeService('humidifier', {value: services.value.humidifier.value , mode: services.value.humidifier.value === 'Авто' ? false : 'Авто'})}
                            >
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4898 1.91657C11.3638 1.91781 11.2393 1.94398 11.1233 1.99361C11.0074 2.04325 10.9024 2.11538 10.8142 2.20584C10.7261 2.2963 10.6565 2.40339 10.6096 2.52087C10.5627 2.63835 10.5393 2.76393 10.5408 2.8905V10.5937C10.5408 10.8492 10.6419 11.0942 10.8218 11.2749C11.0018 11.4555 11.2457 11.557 11.5001 11.557C11.7545 11.557 11.9985 11.4555 12.1784 11.2749C12.3583 11.0942 12.4594 10.8492 12.4595 10.5937V2.8905C12.461 2.76219 12.4369 2.63485 12.3887 2.51601C12.3405 2.39717 12.2692 2.28918 12.1788 2.19844C12.0885 2.1077 11.981 2.03606 11.8627 1.98764C11.7443 1.93923 11.6176 1.91507 11.4898 1.91657ZM17.0813 3.84753C17.0501 3.84662 17.0188 3.84725 16.9877 3.8494C16.7982 3.86556 16.6178 3.93799 16.4694 4.05743C16.321 4.17687 16.2113 4.33791 16.1543 4.52014C16.0973 4.70236 16.0956 4.89755 16.1493 5.08078C16.203 5.264 16.3098 5.42702 16.4561 5.54912C18.1163 6.96519 19.1666 9.07287 19.1666 11.4397C19.1666 15.7133 15.7501 19.1579 11.5048 19.1579C7.25955 19.1579 3.83551 15.7133 3.83552 11.4397C3.83552 9.08656 4.87251 6.9932 6.51612 5.5773C6.61187 5.49521 6.69059 5.39494 6.74778 5.28228C6.80497 5.16963 6.8395 5.04678 6.84942 4.92072C6.85933 4.79466 6.84442 4.66793 6.80555 4.54766C6.76668 4.42739 6.7046 4.31599 6.62286 4.21981C6.54112 4.12364 6.44132 4.04457 6.32916 3.98713C6.217 3.9297 6.09468 3.89495 5.96917 3.885C5.84366 3.87505 5.71744 3.89005 5.59769 3.9291C5.47795 3.96815 5.36704 4.03049 5.27129 4.11259C3.21955 5.88008 1.9168 8.51461 1.91679 11.4397C1.91679 16.7521 6.2219 21.0832 11.5048 21.0832C16.7877 21.0832 21.0835 16.7521 21.0835 11.4397C21.0835 8.49755 19.7678 5.85218 17.6953 4.0844C17.5248 3.93518 17.3074 3.85128 17.0813 3.84753H17.0813Z" fill="#858585"/>
                                </svg>
                            </button>
                            <div>${text_obj.value.humidifier_mode_auto}</div>
                        </div>
                        <div class="item">
                            <button
                                    type="button"
                                    @click=${()=>changeService('humidifier', {value: services.value.humidifier.value , mode: services.value.humidifier.value === 'Режим 1' ? false : 'Режим 1'})}
                            >
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4898 1.91657C11.3638 1.91781 11.2393 1.94398 11.1233 1.99361C11.0074 2.04325 10.9024 2.11538 10.8142 2.20584C10.7261 2.2963 10.6565 2.40339 10.6096 2.52087C10.5627 2.63835 10.5393 2.76393 10.5408 2.8905V10.5937C10.5408 10.8492 10.6419 11.0942 10.8218 11.2749C11.0018 11.4555 11.2457 11.557 11.5001 11.557C11.7545 11.557 11.9985 11.4555 12.1784 11.2749C12.3583 11.0942 12.4594 10.8492 12.4595 10.5937V2.8905C12.461 2.76219 12.4369 2.63485 12.3887 2.51601C12.3405 2.39717 12.2692 2.28918 12.1788 2.19844C12.0885 2.1077 11.981 2.03606 11.8627 1.98764C11.7443 1.93923 11.6176 1.91507 11.4898 1.91657ZM17.0813 3.84753C17.0501 3.84662 17.0188 3.84725 16.9877 3.8494C16.7982 3.86556 16.6178 3.93799 16.4694 4.05743C16.321 4.17687 16.2113 4.33791 16.1543 4.52014C16.0973 4.70236 16.0956 4.89755 16.1493 5.08078C16.203 5.264 16.3098 5.42702 16.4561 5.54912C18.1163 6.96519 19.1666 9.07287 19.1666 11.4397C19.1666 15.7133 15.7501 19.1579 11.5048 19.1579C7.25955 19.1579 3.83551 15.7133 3.83552 11.4397C3.83552 9.08656 4.87251 6.9932 6.51612 5.5773C6.61187 5.49521 6.69059 5.39494 6.74778 5.28228C6.80497 5.16963 6.8395 5.04678 6.84942 4.92072C6.85933 4.79466 6.84442 4.66793 6.80555 4.54766C6.76668 4.42739 6.7046 4.31599 6.62286 4.21981C6.54112 4.12364 6.44132 4.04457 6.32916 3.98713C6.217 3.9297 6.09468 3.89495 5.96917 3.885C5.84366 3.87505 5.71744 3.89005 5.59769 3.9291C5.47795 3.96815 5.36704 4.03049 5.27129 4.11259C3.21955 5.88008 1.9168 8.51461 1.91679 11.4397C1.91679 16.7521 6.2219 21.0832 11.5048 21.0832C16.7877 21.0832 21.0835 16.7521 21.0835 11.4397C21.0835 8.49755 19.7678 5.85218 17.6953 4.0844C17.5248 3.93518 17.3074 3.85128 17.0813 3.84753H17.0813Z" fill="#858585"/>
                                </svg>
                            </button>
                            <div>${text_obj.value.humidifier_mode_1}</div>
                        </div>
                        <div class="item">
                            <button
                                    type="button"
                                    @click=${()=>changeService('humidifier', {value: services.value.humidifier.value , mode: services.value.humidifier.value === 'Режим 2' ? false : 'Режим 2'})}
                            >
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4898 1.91657C11.3638 1.91781 11.2393 1.94398 11.1233 1.99361C11.0074 2.04325 10.9024 2.11538 10.8142 2.20584C10.7261 2.2963 10.6565 2.40339 10.6096 2.52087C10.5627 2.63835 10.5393 2.76393 10.5408 2.8905V10.5937C10.5408 10.8492 10.6419 11.0942 10.8218 11.2749C11.0018 11.4555 11.2457 11.557 11.5001 11.557C11.7545 11.557 11.9985 11.4555 12.1784 11.2749C12.3583 11.0942 12.4594 10.8492 12.4595 10.5937V2.8905C12.461 2.76219 12.4369 2.63485 12.3887 2.51601C12.3405 2.39717 12.2692 2.28918 12.1788 2.19844C12.0885 2.1077 11.981 2.03606 11.8627 1.98764C11.7443 1.93923 11.6176 1.91507 11.4898 1.91657ZM17.0813 3.84753C17.0501 3.84662 17.0188 3.84725 16.9877 3.8494C16.7982 3.86556 16.6178 3.93799 16.4694 4.05743C16.321 4.17687 16.2113 4.33791 16.1543 4.52014C16.0973 4.70236 16.0956 4.89755 16.1493 5.08078C16.203 5.264 16.3098 5.42702 16.4561 5.54912C18.1163 6.96519 19.1666 9.07287 19.1666 11.4397C19.1666 15.7133 15.7501 19.1579 11.5048 19.1579C7.25955 19.1579 3.83551 15.7133 3.83552 11.4397C3.83552 9.08656 4.87251 6.9932 6.51612 5.5773C6.61187 5.49521 6.69059 5.39494 6.74778 5.28228C6.80497 5.16963 6.8395 5.04678 6.84942 4.92072C6.85933 4.79466 6.84442 4.66793 6.80555 4.54766C6.76668 4.42739 6.7046 4.31599 6.62286 4.21981C6.54112 4.12364 6.44132 4.04457 6.32916 3.98713C6.217 3.9297 6.09468 3.89495 5.96917 3.885C5.84366 3.87505 5.71744 3.89005 5.59769 3.9291C5.47795 3.96815 5.36704 4.03049 5.27129 4.11259C3.21955 5.88008 1.9168 8.51461 1.91679 11.4397C1.91679 16.7521 6.2219 21.0832 11.5048 21.0832C16.7877 21.0832 21.0835 16.7521 21.0835 11.4397C21.0835 8.49755 19.7678 5.85218 17.6953 4.0844C17.5248 3.93518 17.3074 3.85128 17.0813 3.84753H17.0813Z" fill="#858585"/>
                                </svg>
                            </button>
                            <div>${text_obj.value.humidifier_mode_2}</div>
                        </div>
                        <div class="item">
                            <button
                                    type="button"
                                    @click=${()=>changeService('humidifier', {value: services.value.humidifier.value , mode: services.value.humidifier.value === 'Режим 3' ? false : 'Режим 3'})}
                            >
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4898 1.91657C11.3638 1.91781 11.2393 1.94398 11.1233 1.99361C11.0074 2.04325 10.9024 2.11538 10.8142 2.20584C10.7261 2.2963 10.6565 2.40339 10.6096 2.52087C10.5627 2.63835 10.5393 2.76393 10.5408 2.8905V10.5937C10.5408 10.8492 10.6419 11.0942 10.8218 11.2749C11.0018 11.4555 11.2457 11.557 11.5001 11.557C11.7545 11.557 11.9985 11.4555 12.1784 11.2749C12.3583 11.0942 12.4594 10.8492 12.4595 10.5937V2.8905C12.461 2.76219 12.4369 2.63485 12.3887 2.51601C12.3405 2.39717 12.2692 2.28918 12.1788 2.19844C12.0885 2.1077 11.981 2.03606 11.8627 1.98764C11.7443 1.93923 11.6176 1.91507 11.4898 1.91657ZM17.0813 3.84753C17.0501 3.84662 17.0188 3.84725 16.9877 3.8494C16.7982 3.86556 16.6178 3.93799 16.4694 4.05743C16.321 4.17687 16.2113 4.33791 16.1543 4.52014C16.0973 4.70236 16.0956 4.89755 16.1493 5.08078C16.203 5.264 16.3098 5.42702 16.4561 5.54912C18.1163 6.96519 19.1666 9.07287 19.1666 11.4397C19.1666 15.7133 15.7501 19.1579 11.5048 19.1579C7.25955 19.1579 3.83551 15.7133 3.83552 11.4397C3.83552 9.08656 4.87251 6.9932 6.51612 5.5773C6.61187 5.49521 6.69059 5.39494 6.74778 5.28228C6.80497 5.16963 6.8395 5.04678 6.84942 4.92072C6.85933 4.79466 6.84442 4.66793 6.80555 4.54766C6.76668 4.42739 6.7046 4.31599 6.62286 4.21981C6.54112 4.12364 6.44132 4.04457 6.32916 3.98713C6.217 3.9297 6.09468 3.89495 5.96917 3.885C5.84366 3.87505 5.71744 3.89005 5.59769 3.9291C5.47795 3.96815 5.36704 4.03049 5.27129 4.11259C3.21955 5.88008 1.9168 8.51461 1.91679 11.4397C1.91679 16.7521 6.2219 21.0832 11.5048 21.0832C16.7877 21.0832 21.0835 16.7521 21.0835 11.4397C21.0835 8.49755 19.7678 5.85218 17.6953 4.0844C17.5248 3.93518 17.3074 3.85128 17.0813 3.84753H17.0813Z" fill="#858585"/>
                                </svg>
                            </button>
                            <div>${text_obj.value.humidifier_mode_3}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '59a0e04b5c';
export default framework7Component;