/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $update = _ref.$update,
    $store = _ref.$store,
    $on = _ref.$on;
  var hotel_tab = $store.getters.hotel_tabs_obj;
  var text_obj = $store.getters.text_obj;
  var language = $store.getters.language;
  var unread_messages = $store.getters.get_unread_msg;
  var is_coworking = $store.getters.is_coworking;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div id="app">
        <!-- Views/Tabs container -->
        <div class="views tabs safe-areas">
            <!-- Tabbar for switching views-tabs -->
            <div class="toolbar toolbar-bottom tabbar-labels">
                <div class="toolbar-inner">

                    <!-- home -->
                    ${hotel_tab.value?.tabs?.my_room_title ?
                        <a href="#view-home" class="tab-link tab-link-active media-badge">
                            <i class="icon icon-home"></i>
                            <span class="tabbar-label">{text_obj.value.nav_home}</span>
                        </a>
                    :  
                        <a href="#view-information" class="tab-link media-badge">
                            <i class="icon icon-information"></i>
                            <span class="tabbar-label">{text_obj.value.nav_information}</span>
                        </a>}

                    <!-- chat -->
                    ${hotel_tab.value?.tabs?.chat_title ?
                        <a href="#view-chat" class="tab-link media-badge">
                            <i class="icon icon-chat"></i>
                            {unread_messages.value
                                ?
                                <span class="badge chat-badge">{unread_messages.value}</span>
                                :
                                ''}
                            <span class="tabbar-label">{text_obj.value.nav_chat}</span>
                        </a>
                    : ''} 
                    
                    <!-- food -->
                    ${hotel_tab.value?.tabs?.food_title ?
                        <a href="#view-food" data-food_id="1" class="tab-link media-badge">
                            <i class="icon icon-food"></i>
                            <span class="tabbar-label">{text_obj.value.nav_food}</span>
                        </a>
                    :''}

                    <!-- food 2 -->
                    ${hotel_tab.value?.tabs?.food_title2 ?
                        <a href="#view-food2" data-food_id="2" class="tab-link media-badge">
                            <i class="icon icon-food"></i>
                            <span class="tabbar-label">{text_obj.value.nav_food2}</span>
                        </a>
                    :''}

                    <!-- shop -->
                    ${hotel_tab.value?.tabs?.shop_new_title ?
                        <a href="#view-shop" class="tab-link media-badge">
                            <i class="icon icon-shop"></i>
                            <span class="tabbar-label">{text_obj.value.nav_shop}</span>
                        </a>
                    :''}

                    <!-- information -->
                    ${hotel_tab.value?.tabs?.my_room_title ?
                        <a href="#view-information" class="tab-link media-badge">
                            <i class="icon icon-information"></i>
                            <span class="tabbar-label">{text_obj.value.nav_information}</span>
                        </a>
                    :''} 

                  
                </div>
            </div>
            <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
            <div id="view-home" class="view view-main view-init tab tab-active" data-name="home" data-url="/room-management/"></div>

            <!-- Chat View -->
            <div id="view-chat" class="view view-init tab" data-name="chat" data-url="/chat/"></div>

            <!-- Food View -->
            <div id="view-food" class="view view-init tab" data-name="food" data-url="/food/1/"></div>

            <!-- Food View 2 -->
            ${hotel_tab.value?.tabs?.food_title2 ?
                <div id="view-food2" class="view view-init tab" data-name="food" data-url="/food/2/"></div>
            :''}

            <!-- Shop View -->
            <div id="view-shop" class="view view-init tab" data-name="shop" data-url="/shop/"></div>

            <!-- Information View -->
            <div id="view-information" class="view view-init tab" data-name="information" data-url="/information/"></div>
        </div>
      
    </div>   
`
    }
    ;
}
framework7Component.id = '2c038f15d3';
export default framework7Component;