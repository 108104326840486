/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on,
    $update = _ref.$update,
    $store = _ref.$store;
  var tabs = $store.getters.information_tabs;
  var language = $store.getters.language;
  var text_obj = $store.getters.text_obj;
  var hotel_tabs_obj = $store.getters.hotel_tabs_obj;
  var show_info_notification_information_page = $store.getters.show_info_notification_information_page;
  var show_info_notification = $store.getters.show_info_notification_getter;
  var coworking = $store.getters.coworking;
  $('#view-information').on('tab:show', function () {
    $store.dispatch('getLocalizationList');
    $store.dispatch('getRemoteServerName');
  });

  // закрыть уведомление
  $$(document).on('click', '#close-notification-info-btn', function () {
    $$('.notification-info').slideUp(300);
    $store.dispatch('closeInfoNotificationInformationPage');
  });
  $on('pageInit', function () {
    $store.dispatch('getLocalizationList');
    $store.dispatch('getRemoteServerName');
    $$(document).on('click', '#logout_info_btn', function () {
      if (window.appMetrica && ($f7.device.android || $f7.device.ios)) {
        window.appMetrica.reportEvent('Нажатие кнопки "Выход"', {
          booking_id: $f7.booking_id,
          uuid: $f7.uuid,
          room_num: $f7.room_num
        });
      }
      $f7.reset_app();
    });
    $(document).on('click', '.col', function (e) {
      var sectionName = $(this).find('.information-tab-item-title').text().trim().toLowerCase().replace('ё', 'е');
      if (sectionName === 'партнеры' || sectionName === 'partners') {
        if (window.appMetrica && ($f7.device.android || $f7.device.ios)) {
          window.appMetrica.reportEvent('Переход на вкладку партнеры', {
            id: $f7.booking_id,
            uuid: $f7.uuid
          });
        }
      }
    });
    $('#language_change').find('option[value="' + window.language + '"]').prop('selected', true);

    //$('#language_change').on('change', function (e) {
    $$(document).on('change', '#language_change', function (e) {
      localStorage.setItem('language', e.target.value);
      window.language = e.target.value;
      $update(); // Обновить текущую станицу
      $f7.views.main.router.refreshPage(); // Обновить главную станицу
      $f7.views.chat.router.refreshPage(); // Обновить чат

      // Обновить нижнее меню
      /*$('.toolbar a.tab-link').forEach(el => {
          const id = el.hash.substr(6);
          const langId = `nav_${id}`;
          const text = text_obj.value[langId];
          $(el).find('span.tabbar-label').text(text);
      });*/

      $store.dispatch('refreshLanguage');
      $f7.smartSelect.get('.smart-select').close();
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page information-page no-navbar" data-name="information">
        <div class="page-content">
            <!--slider-->
            <div data-pagination='{"el": ".swiper-pagination"}' class="swiper swiper-init information-page-swiper">
                <div class="swiper-pagination"></div>
                <div class="swiper-wrapper">
                    ${hotel_tabs_obj.value.info_pic && hotel_tabs_obj.value.info_pic.length > 0 ? hotel_tabs_obj.value?.info_pic.map(item => $h `
                    <div class="swiper-slide">
                        <img src="${item}" class="lazy lazy-fade-in demo-lazy" />
                    </div>
                    `) : $h`
                    <div class="swiper-slide">
                        <img
                            src="./static/mainSlider1_x2.jpeg"
                            class="lazy lazy-fade-in demo-lazy"
                        />
                    </div>
                    <div class="swiper-slide">
                        <img
                            src="./static/mainSlider2_x2.jpeg"
                            class="lazy lazy-fade-in demo-lazy"
                        />
                    </div>
                    <div class="swiper-slide">
                        <img
                            src="./static/mainSlider3_x2.jpeg"
                            class="lazy lazy-fade-in demo-lazy"
                        />
                    </div>
                    `}
                </div>
            </div>

            <!--list-->
            <div class="block information-list">
                <div class="notification-info">
                    ${hotel_tabs_obj?.value?.manage_additional_html?.title_ru && show_info_notification_information_page.value && !show_info_notification.value &&
                    <div class="service-block">
                        <div id="close-notification-info-btn">
                            <svg width="48" height="44" viewBox="0 0 48 44" fill="none">
                                <path opacity="0.12" d="M24 34C30.6274 34 36 28.6274 36 22C36 15.3726 30.6274 10 24 10C17.3726 10 12 15.3726 12 22C12 28.6274 17.3726 34 24 34Z" fill="#1E1E24" />
                                <path
                                    d="M28.7364 17.2636C29.0879 17.6151 29.0879 18.1849 28.7364 18.5364L25.273 22L28.7364 25.4636C29.0586 25.7858 29.0854 26.2915 28.817 26.6442L28.7364 26.7364C28.3849 27.0879 27.8151 27.0879 27.4636 26.7364L24 23.273L20.5364 26.7364C20.1849 27.0879 19.6151 27.0879 19.2636 26.7364C18.9121 26.3849 18.9121 25.8151 19.2636 25.4636L22.727 22L19.2636 18.5364C18.9414 18.2142 18.9146 17.7085 19.1831 17.3558L19.2636 17.2636C19.6151 16.9121 20.1849 16.9121 20.5364 17.2636L24 20.727L27.4636 17.2636C27.8151 16.9121 28.3849 16.9121 28.7364 17.2636Z"
                                    fill="#1E1E24"
                                />
                            </svg>
                        </div>

                        <div class="color-vertical-orange title">
                            {language.value=='ru' ? hotel_tabs_obj.value.manage_additional_html.title_ru : hotel_tabs_obj.value.manage_additional_html.title_en}
                        </div>
                        <div innerHTML={language.value=='ru'? hotel_tabs_obj.value.manage_additional_html.info_ru:hotel_tabs_obj.value.manage_additional_html.info_en}></div>
                    </div>
                     }
                </div>
                <div class="row">

                    <!--Звонок newtel-->
                    ${$store.getters?.room_fields?.value.length > 0 ?
                    $store.getters?.room_fields?.value.map((item, index) => $h `
                    ${item.field == 'newtel_link_code' ?
                    $h `
                    <div class="col">
                        <a target="_system" class="link external" href=${$store.getters.programming_fields.value.information_newtel_server + item.value}>
                            <div class="information-tab-item-img" innerHTML=${$store.getters.programming_fields.value.information_newtel_call_logo}></div>
                            <div class="information-tab-item-title">${text_obj.value.information_call_reseption}</div>
                        </a>
                    </div>
                    `
                    : $h ` `
                    }
                    `)
                    : ''}
                    <!--Звонок newtel КОНЕЦ-->

                    <!-- скрыт профиль пользователя -->
                    <!-- <div class="col">
                        <a href="/profile/">
                            <img class="information-tab-item-img" src="./static/information/profile.svg" alt="" />
                            <div class="information-tab-item-title">${text_obj.value.profile_title}</div>
                        </a>
                    </div> -->
                    ${tabs.value.length > 0 ? tabs.value.map(item => $h `
                    <div class="col">
                        <!--prettier-ignore-->
                        <a target="${item.instagram}" class="${item.instagram_link}"
                        href="${item.href_link}"
                            >
                            ${item.svg_current ?
                            <div class="information-tab-item-img" innerHTML={item.svg_current}></div>
                            :
                            <img class="information-tab-item-img" src="./static/information/tabs-no-pic.svg" alt="" />
                            }
                            <div class="information-tab-item-title">${language.value === 'ru' ? item.name :
                                item.name_en}</div>
                        </a>
                    </div>
                    `) : ''}

                    <a
                        class="col smart-select smart-select-init"
                        data-open-in="popup"
                        data-searchbar="false"
                        data-popup-close-link-text='<svg width="16" height="16" viewBox="0 0 64 64"><path d="m4.59 59.41a2 2 0 0 0 2.83 0l24.58-24.58 24.59 24.58a2 2 0 0 0 2.83-2.83l-24.59-24.58 24.58-24.59a2 2 0 0 0 -2.83-2.83l-24.58 24.59-24.59-24.58a2 2 0 0 0 -2.82 2.82l24.58 24.59-24.58 24.59a2 2 0 0 0 0 2.82z" fill="#000000" /></svg>'
                    >
                        <select name="language" id="language_change">
                            <option value="ru">${text_obj.value.information_language_ru}</option>
                            <option value="en">${text_obj.value.information_language_en}</option>
                        </select>
                        <img class="information-tab-item-img" src="./static/information/tabs-language.svg" alt="" />
                        <div class="information-tab-item-title">${text_obj.value.information_language}</div>
                    </a>
                    <div class="col">
                        <a href="/information/feedback/">
                            <img class="information-tab-item-img" src="./static/information/tabs-feedback.svg" alt="" />
                            <div class="information-tab-item-title">${text_obj.value.feedback_nav_title}</div>
                        </a>
                    </div>
                    <a class="col" id="logout_info_btn">
                        <img class="information-tab-item-img" src="./static/information/tabs-logout.svg" alt="" />
                        <div class="information-tab-item-title">${text_obj.value.information_log_out}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '60bf2027d0';
export default framework7Component;