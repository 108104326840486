/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  var services = $store.getters.services;
  var text_obj = $store.getters.text_obj;
  var changeService = function changeService(type, value, trueColor) {
    var hsv = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    $$('#current_color').val(value).trigger('change');
    $store.dispatch('setService', {
      type: type,
      value: value,
      trueColor: trueColor,
      hsv: hsv
    });
  };
  $on('pageInit', function () {
    var colorPicker = new iro.ColorPicker("#picker", {
      width: 320,
      color: services.value.light_on_off || '#fff',
      borderWidth: 1,
      borderColor: "#fff"
    });
    colorPicker.on('color:change', function (color) {
      var t = localStorage.getItem('tm');
      var tnow = Date.now();
      var trueColor = color.rgb.r; // * parseInt(color.rgb.g) * parseInt(color.rgb.b);
      trueColor = (trueColor << 8) + color.rgb.g;
      trueColor = (trueColor << 8) + color.rgb.b;
      if (tnow - t > 1000) {
        // Макс чтобы не баловался
        localStorage.setItem('tm', tnow);
        changeService('light_on_off', color.hexString, trueColor, color.hsv);
      }
    });
    $$('#current_color').on('change', function (e) {
      colorPicker.color.hexString = e.target.value;
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-toolbar">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7903 4.3871L10.7071 4.29289C10.3466 3.93241 9.77939 3.90468 9.3871 4.2097L9.29289 4.29289L2.29289 11.2929L2.2515 11.3369L2.19633 11.4047L2.12467 11.5159L2.07123 11.6287L2.03585 11.734L2.00691 11.8819L2 12L2.00279 12.0752L2.02024 12.2007L2.04974 12.3121L2.09367 12.4232L2.146 12.5207L2.21969 12.6254L2.29289 12.7071L9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071C11.0676 19.3466 11.0953 18.7794 10.7903 18.3871L10.7071 18.2929L5.416 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H5.414L10.7071 5.70711C11.0676 5.34662 11.0953 4.77939 10.7903 4.3871L10.7071 4.29289L10.7903 4.3871Z" fill="black"/>
                        </svg>
                    </a>
                </div>
                <div class="title">${text_obj.value.my_room_lent}</div>
            </div>
        </div>
        <div class="page-content">
            <div class="block">
                <div class="lent-block">
                    <div id="picker" class="lent-wheel" color="#64FF67"></div>

                    <input type="hidden" id="current_color" />

                    <div class="buttons">
                        <button
                                class='${services.value.light_on_off === "#64FF67" ? "active" : ""}'
                                type="button"
                                @click=${()=> changeService('light_on_off', '#64FF67', 6618983, {"h":121,"s":61,"v":100})}
                                style="background: #64FF67"
                        ></button>
                        <button
                                class='${services.value.light_on_off === "#5BF1F7" ? "active" : ""}'
                                type="button"
                                style="background: #5BF1F7"
                                @click=${()=>changeService('light_on_off', '#5BF1F7', 6618983, {"h":182,"s":63,"v":97})}
                        ></button>
                        <button
                                class='${services.value.light_on_off === "#5A92FF" ? "active" : ""}'
                                type="button"
                                style="background: #5A92FF"
                                @click=${()=>changeService('light_on_off', '#5A92FF', 6618983, {"h":220,"s":65,"v":100})}
                        ></button>
                        <button
                                class='${services.value.light_on_off === "#FF5780" ? "active" : ""}'
                                type="button"
                                style="background: #FF5780"
                                @click=${()=>changeService('light_on_off', '#FF5780', 6618983, {"h":345,"s":66,"v":100})}
                        ></button>
                        <button
                                class='${services.value.light_on_off === "#FF845C" ? "active" : ""}'
                                type="button"
                                style="background: #FF845C"
                                @click=${()=>changeService('light_on_off', '#FF845C', 6618983, {"h":15,"s":64,"v":100})}
                        ></button>
                        <button
                                class='${services.value.light_on_off === "#FFCF5F" ? "active" : ""}'
                                type="button"
                                style="background: #FFCF5F"
                                @click=${()=>changeService('light_on_off', '#FFCF5F', 6618983, {"h":42,"s":63,"v":100})}
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '53cc51e77a';
export default framework7Component;