/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  var root = $store.getters.root;

  // Id зачади - передается через пропсы
  var taskId = props.taskId;
  var messages;
  var messagebar;
  var getMgs = function getMgs() {
    if ($f7.booking_id) {
      $f7.request.get("".concat(root.value, "?r=api/getmessagesbytaskid"), {
        booking_id: $f7.booking_id,
        task_id: taskId
      }).then(function (res) {
        try {
          var v = JSON.parse(res.data);
          v = v.data.comments;
          v = v.reverse();
          if (v.length > 0) {
            for (var key in v) {
              var msg = {
                text: v[key].text,
                attrs: {
                  'data-id': v[key].created_at
                }
              };
              var isset_msg = $('.task-messages.messages div[data-id="' + v[key].created_at + '"] .message-text').html();
              //console.log(msg);
              if (!isset_msg) {
                messages.addMessage(msg);
              }
            }
          }
        } catch (e) {
          return false;
        }
      }).catch(function (err) {
        $f7.dialog.alert(text_obj.value.chat_kaizen_error_2, function () {
          location.reload();
        });
      });
    }
  };
  $on('pageInit', function () {
    if (window.appMetrica) {
      window.appMetrica.reportEvent('Переход в комментарии к задаче kaizen', {
        booking_id: $f7.booking_id,
        uuid: $f7.uuid,
        room_num: $f7.room_num,
        taskId: taskId
      });
    }
    // Init Messages
    messages = $f7.app_messages('.task-messages');

    // Init Messagebar
    messagebar = $f7.app_messagebar('.task-messages'); /*$f7.messagebar.create({
                                                       el: '.messagebar.task-messages'
                                                       });*/

    /*messages.addMessage({
        text: 'Привет! Мы искренне рады, что вы с нами. Возникли какие-либо вопросы? Мы вам поможем.',
        type: 'received',
    });*/

    getMgs();
    setInterval(function timeout() {
      var _$f7$views;
      var v = $f7 === null || $f7 === void 0 || (_$f7$views = $f7.views) === null || _$f7$views === void 0 || (_$f7$views = _$f7$views.current) === null || _$f7$views === void 0 ? void 0 : _$f7$views.history[0];
      if (v.includes('/chat-dynamic/')) {
        getMgs();
      }
    }, 8000);
    $(document).on('click', '#task_chat_send_message', function (e) {
      console.log(e);
      var text = messagebar.getValue().replace(/\n/g, '<br>').trim();
      $f7.request.get("".concat(root.value, "?r=api/addmessagebytaskid"), {
        booking_id: $f7.booking_id,
        task_id: taskId,
        comment: text
      }).then(function (res) {
        // return if empty message
        if (!text.length) return;
        messagebar.clear();
      }).catch(function (err) {
        $f7.dialog.alert(text_obj.value.chat_kaizen_error_2, function () {
          location.reload();
        });
      });
    });

    // Пример запроса - если будешь делать
    // let result = {};
    // $f7.request.get('https://vertical-hotel.ru/app/rooms.php', { roomId: props.roomId})
    //     .then(function (res) {
    //         result = JSON.parse(res.data);
    //         $$('#room-item-content').html(result.content);
    //         $$('#room-item-name').text(result.name);
    //         $$('#room-img').attr('src', result.imgLink)
    //     })
    //     .catch(function (err) {
    //         console.log(err.xhr)
    //         console.log(err.status)
    //         console.log(err.message)
    //     });
  });

  // Return render function
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="chat-dynamic">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="title">${text_obj.value.localizechat_2_title}</div>
                <div class="right">
                    <a class="link popup-close" href="#"><img src="./static/svg/close.svg" /></a>
                </div>
            </div>
        </div>

        <div class="toolbar messagebar task-messages">
            <div class="toolbar-inner">
                <div class="messagebar-area">
                    <textarea class="resizable" placeholder="${text_obj.value.chat_message}"></textarea>
                </div>

                <a class="link send-link icon-only" href="#" id="task_chat_send_message">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg_icon">
                        <path
                            d="M35.5533 20.014C35.5641 19.1778 35.1112 18.4093 34.3757 18.0059L17.2351 8.58843C16.4674 8.15453 15.5624 8.20784 14.839 8.70183C14.1023 9.20414 13.7252 10.3999 13.9312 11.2636L15.5313 17.9675C15.6956 18.655 16.3108 19.139 17.0186 19.136L26.5573 19.1064C27.0458 19.0966 27.4419 19.4927 27.4321 19.9812C27.4306 20.4614 27.0403 20.8517 26.5518 20.8615L17.0041 20.8836C16.2963 20.885 15.678 21.3712 15.5095 22.0597L13.8528 28.7903C13.6516 29.5857 13.8809 30.3797 14.4421 30.9409C14.5082 31.007 14.5825 31.0812 14.6568 31.139C15.3836 31.6996 16.3354 31.7712 17.148 31.3382L34.3467 22.0128C35.0847 21.6215 35.5424 20.8501 35.5533 20.014"
                            fill="#ED6947"
                        />
                    </svg>
                </a>
            </div>
        </div>

        <div class="page-content messages-content">
            <div class="messages task-messages">
                <div class="messages-title"><b>${text_obj.value.chat_2_title} #${taskId}</b></div>
                <!--<div class="message message-received">
                    <div class="message-content">
                        <div class="message-bubble">
                            <div class="message-text">Привет! Мы искренне рады, что вы с нами. Возникли какие-либо вопросы? Мы вам поможем. </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = 'c8c62dffec';
export default framework7Component;