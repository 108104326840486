import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $store = _ref.$store,
    $f7 = _ref.$f7;
  var text_obj = $store.getters.text_obj;
  var user = $store.getters.user;
  var user_update = $store.getters.user_update;
  var getBooking = function getBooking() {
    $store.dispatch('getBooking', {
      uuid: localStorage.getItem('uuid'),
      booking_id: localStorage.getItem('booking_id')
    });
  };
  var updateUser = function updateUser() {
    var data = $f7.form.convertToData('#update-user-form');
    if (!user_update.value.update_in_progress) {
      $store.dispatch('updateUser', _objectSpread(_objectSpread({}, data), {}, {
        uuid: localStorage.getItem('uuid'),
        Name: data.name
      }));
    }
  };
  $on('pageInit', function () {
    //делаем неактивную кнопку "Сохранить", если имя пустое
    $$('#name').on('input', function (e) {
      if (e.target.value.length === 0) {
        $$('#button').attr('disabled', true);
      } else {
        $$('#button').attr('disabled', false);
      }
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page bg-color-white no-toolbar">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg_icon">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.23989 0.333496H16.7716C20.8899 0.333496 23.6666 2.983 23.6666 6.93683V17.0518C23.6666 21.0068 20.8899 23.6668 16.7716 23.6668H7.23989C3.10989 23.6668 0.333221 21.0068 0.333221 17.0518V6.93683C0.333221 2.983 3.10989 0.333496 7.23989 0.333496ZM9.35155 12.8752H16.7599C17.2499 12.8752 17.6349 12.4785 17.6349 12.0002C17.6349 11.5102 17.2499 11.1252 16.7599 11.1252H9.35155L12.2449 8.2435C12.4082 8.08016 12.5016 7.84683 12.5016 7.62516C12.5016 7.40466 12.4082 7.18183 12.2449 7.00683C11.9066 6.6685 11.3466 6.6685 11.0082 7.00683L6.60989 11.3818C6.28322 11.7085 6.28322 12.2918 6.60989 12.6185L11.0082 16.9935C11.3466 17.3318 11.9066 17.3318 12.2449 16.9935C12.5832 16.6435 12.5832 16.0952 12.2449 15.7452L9.35155 12.8752Z"
                                fill="#ED6947"
                            />
                        </svg>
                    </a>
                </div>
                <div class="title">${text_obj.value.profile_edit_title}</div>
            </div>
        </div>
        <div class="page-content profile-edit-content login-content">
            <!--            <div class="block">-->
            <!--                <div class="user-info-block">-->
            <!--                    <div class="img">-->
            <!--                        <img src="#" alt="" class="lazy lazy-fade-in demo-lazy" />-->
            <!--                    </div>-->
            <!--                    <a class="link" href="#">${text_obj.value.profile_edit_photo}</a>-->
            <!--                </div>-->
            <!--            </div>-->

            <form class="form-block" id="update-user-form">
                <div class="block item-content item-input item-input-with-info">
                    <div class="item-title item-label">${text_obj.value.profile_edit_full_name}</div>
                    <div class="item-input-wrap">
                        <input type="text" name="name" id="name" autocomplete="off" placeholder="${text_obj.value.profile_edit_full_name_placeholder}" value="${user.value.name}" required />
                        <span class="input-clear-button"></span>
                    </div>
                </div>

                <div class="block item-content item-input item-input-with-info">
                    <div class="item-title item-label">${text_obj.value.profile_edit_phone}</div>
                    <div class="item-input-wrap">
                        <input type="text" name="phone" id="phone" autocomplete="off" placeholder="${text_obj.value.profile_edit_phone_placeholder}" value="${user.value.phone}" disabled />
                    </div>
                </div>

                <div class="buttons">
                    <div class="block no-margin">
                        <button class="btn" id="button" type="button" name="submit" @click="${updateUser}">${text_obj.value.profile_edit_save_button}</button>
                    </div>
                </div>
            </form>
        </div>
        ${user_update.value.is_updated && getBooking()} ${user_update.value.is_updated && $f7.dialog.alert(`${text_obj.value.profile_edit_alert_success}`)}
    </div>
`
    }
    ;
}
framework7Component.id = '056de94656';
export default framework7Component;