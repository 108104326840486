/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  var food = $store.getters.food;
  var food_basket = $store.getters.food_basket;
  var price = $store.getters.food_total_price;
  var order_sent_success = $store.getters.order_sent_success;
  var isLoading = $store.getters.isLoading;
  var isOpened = $store.getters.food_is_open;
  var min_order_amount = $store.getters.min_order_amount;
  var loadFood = function loadFood() {
    $store.dispatch('getFood');
  };
  var isOpenedBar = function isOpenedBar() {
    $store.dispatch('isOpenedBar');
  };
  var sendOrder = function sendOrder(type) {
    $store.dispatch('sendOrder', {
      type: type
    });
  };
  var sentSheet = function sentSheet() {
    $f7.sheet.open('#order-sent-success-modal-food');
    $store.dispatch('setOrderSentSuccess');
  };
  var setActiveCategory = function setActiveCategory() {
    var categoryButton = $('navbar-food button');
  };
  var scrollToCategory = function scrollToCategory(id) {
    var elem = $(".food-category-title[data-catid='".concat(id, "']"));
    if (elem && elem[0]) {
      $('#food-content').scrollTop(elem[0].offsetTop + 10, 300);
      scrollNav();
    }
  };
  var scrollNav = function scrollNav() {
    var contentElement = $('#food-content');
    var position = contentElement[0].scrollTop;
    var id = null;
    var categories = $('.food-category-title.cat-title');
    categories.forEach(function (cat) {
      if (position > cat.offsetTop) {
        id = $(cat).data('catid');
      }
    });
    if (id) {
      var navElem = $(".navbar-food button[data-catid='".concat(id, "']"));
      $('.navbar-food').scrollLeft(navElem[0].offsetLeft - 16, 30);
    }
  };
  var setActiveButtonNavClass = function setActiveButtonNavClass(id) {
    var items = document.querySelectorAll('.navbar-food .button');
    var elem = $(".navbar-food button[data-catid='".concat(id, "']"));
    Array.from(items).forEach(function (item) {
      item.classList.remove('button-fill');
    });
    elem.addClass('button-fill');
  };
  $(document).on('scroll', '#food-content', function () {
    scrollNav();
  }, true);

  //пролистывание корзины, если много товарных позиций
  var addFixedHeight = function addFixedHeight() {
    var foodBasketListHeight = $('#food-basket .food-basket-list').height();
    if (foodBasketListHeight > $f7.height * 0.8) {
      $('#food-basket').addClass('fixed-height');
    } else {
      $('#food-basket').removeClass('fixed-height');
    }
  };
  var addFoodToBasket = function addFoodToBasket(id, name, price, img_path, weight, mass, count) {
    if (food_basket.value.length > 0 || count === 'plus') {
      $store.dispatch('addFoodToBasket', {
        id: id,
        name: name,
        price: price,
        img_path: img_path,
        weight: weight,
        mass: mass,
        count: count
      });
    }
    if (count === 'delete') {
      addFixedHeight();
    }
  };
  var intervalisOpenedBar = null;
  $('#view-food').on('tab:show', function () {
    loadFood();
    isOpenedBar();

    // слайдер фото
    $(document).on('click', '.pb-popup', function (e) {
      var id = e.target.getAttribute('data-id');
      var category = e.target.getAttribute('data-category');
      var photo = food.value.find(function (el) {
        return el.id === category;
      }).items.find(function (el) {
        return el.id === id;
      });
      var myPhotoBrowserPopupDark = $f7.photoBrowser.create({
        photos: photo.files,
        type: 'popup',
        theme: 'dark'
      });
      myPhotoBrowserPopupDark.open();
    });
    intervalisOpenedBar = setInterval(function () {
      isOpenedBar();
    }, 1000 * 60);
  });
  $('#view-food').on('tab:hide', function () {
    $(document).off('click', '.pb-popup');
    if (intervalisOpenedBar) {
      clearInterval(intervalisOpenedBar);
    }
  });
  $on('pageInit', function () {
    $f7.sheet.create({
      el: '#food-basket',
      swipeToClose: true,
      backdrop: true,
      on: {
        opened: function opened() {
          addFixedHeight();
        }
      }
    });
    $f7.sheet.create({
      el: '#order-sent-success-modal-food',
      swipeToClose: true,
      backdrop: true
    });
  });

  // Return render function
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="food" >
        <div class="navbar food-page-navbar" id="food-navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="title">${text_obj.value.food_title}</div>
            </div>
            <div class="block  row navbar-food">
                ${food.value.length > 0 ?
                food.value.filter(el=>el.hasOwnProperty('id')).map((category, index) => $h `
                <button class="button button-small button-round ${index === 0 ? 'button-fill' : ''}" data-catid=${category.id} @click=${()=> {scrollToCategory(category.id);setActiveButtonNavClass(category.id)}}>${category.name}</button>
                `) : ''}
            </div>
        </div>

        <div class="page-content food-content" id="food-content">
            <div class="block food-category">
                ${food.value.length > 0 ?
                food.value.filter(el=>el.hasOwnProperty('id')).map((category, index) => {
                const foodItems = category.items && category.items.length > 0 ? category.items.map(item => {
                const food_basket_item = food_basket.value.length > 0 && food_basket.value.find(food_basket_item => food_basket_item.id === +item.id);
                return $h `
                <div class="block-strong food-item" data-id=${item.id}>
                    <div class="left">
                        <div class="img">
                            <img  class="pb-popup" src="${item.img_path ? item.img_path : $h`./static/svg/no-img-food.svg`}" data-id=${item.id} data-category="${category.id}" alt="${item.name}"/>
                        </div>
                    </div>
                    <div class="right">
                        <div class="name">${item.name}</div>
                        <div class="info">
                            
                            ${item.price}₽ <span class="gray">${item.weight}${category.mass}</span>
                        </div>

                        <div class="stepper stepper-init">
                            <div class="stepper-button-minus" @click=${ ()=> addFoodToBasket(item.id, item.name, item.price, item.img_path, item.weight, category.mass, 'minus')}></div>
                            <div class="stepper-input-wrap">
                                <input type="text" value="${food_basket_item && food_basket_item.count ? food_basket_item.count : 0}" min="0" max="100" step="1" readonly id="count_${item.id}"/>
                            </div>
                            <div class="stepper-button-plus" @click=${()=> addFoodToBasket(item.id, item.name, item.price, item.img_path, item.weight, category.mass, 'plus')}></div>
                        </div>
                    </div>
                </div>
                `}) : $h `
                <div class="block-strong food-item">
                    <p>${text_obj.value.food_no_goods_in_category}</p>
                </div>
                `;

                return $h `
                <h2 class="food-category-title cat-title" data-catid=${category.id}>${category.name}</h2>
                <div class="food-category-list">
                    ${foodItems}
                </div>
                `
                }) : $h`

                <h2 class="food-category-title skeleton-text skeleton-effect-wave">Category name Category name</h2>
                <div class="food-category-list skeleton-text skeleton-effect-wave">
                    <div class="block-strong food-item">
                        <div class="left">
                            <div class="img skeleton-block">
                            </div>
                        </div>
                        <div class="right">
                            <div class="name">Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food nam</div>
                        </div>
                    </div>
                    <div class="block-strong food-item">
                        <div class="left">
                            <div class="img skeleton-block">
                            </div>
                        </div>
                        <div class="right">
                            <div class="name">Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food name Food nam</div>
                        </div>
                    </div>
                </div>
                `
                }
            </div>
        </div>

        ${food_basket.value.length > 0 ? !isOpened.value.status ? $h`
        <a class="fab fab-center-bottom food-basket-link disabled-button" href="#">${text_obj.value.food_opening_hours} ${isOpened.value.work_from_str} ${text_obj.value.food_opening_hours_2} ${isOpened.value.work_to_str}</a>
        ` : $h `
        <a class="sheet-open fab fab-center-bottom food-basket-link" href="#" data-sheet="#food-basket">${text_obj.value.food_checkout}</a>
        ` : ''}

        <div class="sheet-modal vertical-sheet" id="food-basket">
            <div class="sheet-modal-inner">
                <div class="swipe-handler"></div>
                <div class="block-title food-basket-title">${text_obj.value.food_your_order}</div>

                ${food_basket.value.length > 0 ? $h `
                <div class="page-content">
                    <div class="block food-basket-list">
                        ${food_basket.value.map((item, index) => $h`
                        <div class="food-basket-item">
                            <div class="left">
                                <div class="img">
                                    <img src="${item.img_path ? item.img_path : $h`./static/svg/no-img-food.svg`}" alt="${item.name}"/>
                                </div>
                            </div>
                            <div class="right">
                                <div class="name">${item.name}</div>
                                <div class="info">
                                    ${item.price}₽x${item.count} <span class="gray">${item.weight}${item.mass}</span>
                                </div>
                            </div>
                            <div class="delete">
                                <button @click=${()=> addFoodToBasket(item.id, item.name, item.price, item.img_path, item.weight, item.mass, 'delete')}>
                                    <img src="./static/svg/basket-delete.svg" />
                                </button>
                            </div>
                        </div>
                        `)}
                    </div>
                </div>

                <div class="block food-basket-result">
                    ${min_order_amount.value>price.value
                    ?
                    <div class="amount_notification">
                        Минимальная сумма {min_order_amount.value} руб
                    </div>
                    :
                    ''
                    }
                    <div class="food-basket-result-box">
                        <div class="left">
                            ${price.value}₽
                        </div>

                        <div class="right">
                            ${min_order_amount.value>price.value
                            ?
                            $h`
                            <button disabled class="button button-preloader disabled-button" >
                                <span>${text_obj.value.food_order}</span>
                            </button>
                            `
                            :
                            $h`
                            <button class="button button-preloader ${isLoading.value ? $h`button-loading` : ''}" @click=${()=> sendOrder(1)}>
                                <span class="preloader"></span>
                                <span>${text_obj.value.food_order}</span>
                            </button>
                            `
                            }
                        </div>
                    </div>
                </div>
                ` : ($f7.sheet.close('#food-basket'), $h`<div class="page-content"><div class="food-basket-item">${text_obj.value.shop_basket_empty}</div></div>`)}

            </div>
        </div>


        ${order_sent_success.value > 0 ? sentSheet() : ''}
        <div class="sheet-modal vertical-sheet order-sent-success-modal" id="order-sent-success-modal-food">
            <div class="sheet-modal-inner">
                <div class="swipe-handler"></div>
                <div class="page-content">
                    <p class="img"><img src="./static/svg/tick-circle.svg" alt=""/></p>
                    <p class="title">${text_obj.value.food_thank_for_order}</p>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '1da600249b';
export default framework7Component;