/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $store = _ref.$store;
  var coworking = $store.getters.coworking;
  var declension = function declension(number, txt) {
    var cases = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [2, 0, 1, 1, 1, 2];
    return txt[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  };
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="office">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="title office-title">
                    <div class="sub-title">Мой офис</div>
                    <button type="button" class="history-btn">
                        <a href="/coworking/tariff-history/">
                            <svg width="20" height="20" viewBox="0 0 22 22" fill="none">
                                <path d="M11 21.5C8.31667 21.5 5.97847 20.6104 3.98542 18.8313C1.99236 17.0521 0.85 14.8306 0.558333 12.1667H2.95C3.22222 14.1889 4.12153 15.8611 5.64792 17.1833C7.17431 18.5056 8.95833 19.1667 11 19.1667C13.275 19.1667 15.2049 18.3743 16.7896 16.7896C18.3743 15.2049 19.1667 13.275 19.1667 11C19.1667 8.725 18.3743 6.79514 16.7896 5.21042C15.2049 3.62569 13.275 2.83333 11 2.83333C9.65833 2.83333 8.40417 3.14444 7.2375 3.76667C6.07083 4.38889 5.08889 5.24444 4.29167 6.33333H7.5V8.66667H0.5V1.66667H2.83333V4.40833C3.825 3.16389 5.03542 2.20139 6.46458 1.52083C7.89375 0.840278 9.40556 0.5 11 0.5C12.4583 0.5 13.8243 0.777083 15.0979 1.33125C16.3715 1.88542 17.4799 2.63403 18.4229 3.57708C19.366 4.52014 20.1146 5.62847 20.6687 6.90208C21.2229 8.17569 21.5 9.54167 21.5 11C21.5 12.4583 21.2229 13.8243 20.6687 15.0979C20.1146 16.3715 19.366 17.4799 18.4229 18.4229C17.4799 19.366 16.3715 20.1146 15.0979 20.6687C13.8243 21.2229 12.4583 21.5 11 21.5ZM14.2667 15.9L9.83333 11.4667V5.16667H12.1667V10.5333L15.9 14.2667L14.2667 15.9Z" fill="#1E1E24"/>
                            </svg>
                        </a>
                    </button>
                </div>
            </div>
        </div>
        <div class="page-content">
            <div class="block">
                <div class="coworking-tariff-block">
                    <div class="left">
                        <img src="./static/svg/tariff.svg" alt="icon" />
                    </div>
                    <div class="right">
                        <div class="title">
                            ${coworking.value.tariff_id ? $h`Тариф «${coworking.value.tariff}»` : 'У вас еще нет тарифа'}
                        </div>
                        <div class="description">${!coworking.value.tariff_id && 'Станьте резидентом, чтобы получить доступ ко всем услугам коворкинга!'}</div>
                        <div class="description">${coworking.value.days_left ? $h`До конца тарифа осталось: ${coworking.value.days_left} ${declension(coworking.value.days_left, ['день', 'дня', 'дней'])}` : ''}</div>
                        <div class="buttons-block">
                            ${coworking.value.tariff_id ? $h`
                                <a href=/coworking/tariff/${coworking.value.tariff_id}/ class="btn violet-btn">Продлить</a>
                                <a href="/coworking/tariff-list/" class="btn transparent-btn">Сменить тариф</a>
                            ` :
                                <a href="/coworking/tariff-list/" class="btn violet-btn">Оформить тариф</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = 'ff94dc57dd';
export default framework7Component;