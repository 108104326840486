import jQuery from 'jquery';
import roundSlider from 'round-slider';
import iro from '@jaames/iro';
import $ from 'dom7';
import Framework7, { getDevice } from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

//round-slider styles
import 'round-slider/dist/roundslider.min.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';

// UUID
import { v4 as uuidv4 } from 'uuid';

// semver
import semver from 'semver';

//localization
import './localization';

window.$$ = jQuery;
window.roundSlider = roundSlider;
window.iro = iro;

const root = store.getters.root;

var device = getDevice();
var app = new Framework7({
    name: 'MYS Hotel', // App name
    theme: 'auto', // Automatic theme detection
    el: '#app', // App root element
    component: App, // App main component
    id: 'io.framework7.mys', // App bundle ID
    version: '1.0.0',
    // App store
    store: store,
    // App routes
    routes: routes,
    booking_id: localStorage.getItem('booking_id'),
    method: localStorage.getItem('method'),
    roomNumber: false,
    // Input settings
    input: {
        scrollIntoViewOnFocus: device.cordova && !device.electron,
        scrollIntoViewCentered: device.cordova && !device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
    },

    on: {
        init: function () {
            // запрос непрочитанных сообщений
            app.store.dispatch('getUnreadMsg');

            // AppMetrika
            if (window.appMetrica && (app.device.android || app.device.ios)) {
                // Creating an extended library configuration.
                const configuration = {
                    // Mandatory.
                    apiKey: '6d875c72-d5f9-48b9-a08a-b29063c44b1c',
                    // Optional.
                    locationTracking: false,
                    handleFirstActivationAsUpdate: false,
                    sessionTimeout: 15,
                };
                window.appMetrica.activate(configuration);
            }
            // AppMetrika КОНЕЦ -->

            // geolocation
            if (app.device.android && navigator?.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function (e) {
                        // console.log('geo ok '+ JSON.stringify(e));
                    },
                    function (e) {
                        // console.log('geo error '+ e);
                    },
                    { enableHighAccuracy: true }
                );
            }
            // geolocation КОНЕЦ -->

            // Branch initialization
            if ((typeof Branch && app.device.android) || app.device.ios) {
                /*Branch.initSession().then(function(data) {
                    if (data['+clicked_branch_link']) {
                        // read deep link data on click
                        alert('Deep Link Data: ' + JSON.stringify(data));
                    }
                });*/
            }

            window.language = (localStorage.getItem('language') || window.navigator.language || 'en_US').replace(/-/g, '_').substring(0, 2);

            window.localize = key => {
                let language = window.language.replace(/-/g, '_');

                if (!window.localization[language]) {
                    language = language.substring(0, 2);
                }
                if (!window.localization[language]) {
                    language = 'en';
                }

                return window.localization[language][key] ? window.localization[language][key] : key;
            };

            app.booking_id = localStorage.getItem('booking_id');
            app.hotel_id = localStorage.getItem('hotel_id');
            app.room_num = localStorage.getItem('room_num');
            app.lockData = localStorage.getItem('lockData');
            app.lockMac = localStorage.getItem('lockMac');
            app.pushToken = localStorage.getItem('pushToken');
            app.goto = true;
            app.method = localStorage.getItem('method');

            var getToken = function () {
                if (typeof FirebasePlugin !== 'undefined' && (app.device.android || app.device.ios)) {
                    FirebasePlugin.getToken(
                        function (fcmToken) {
                            localStorage.setItem('pushToken', fcmToken);
                            console.log('########### pushToken ' + fcmToken);
                            app.pushToken = fcmToken;
                            app.request
                                .post('https://dc.mys-hotel.ru/?r=api/sendfcmtoken', {
                                    booking_id: app.booking_id,
                                    pushToken: app.pushToken,
                                    ios: app.device.ios,
                                    android: app.device.android,
                                    app_id: 2,
                                })
                                .then(res2 => {
                                    try {
                                    } catch (e) {
                                        return false;
                                    }
                                });
                        },
                        function (error) {
                            console.error('##################### fcmToken ERROR' + error);
                        }
                    );
                }
            };

            var checkNotificationPermission = function (requested) {
                if (typeof FirebasePlugin !== 'undefined' && (app.device.android || app.device.ios)) {
                    FirebasePlugin.hasPermission(function (hasPermission) {
                        if (hasPermission) {
                            console.log('########### Remote notifications permission granted');
                            getToken();
                        } else if (!requested) {
                            // Request permission
                            console.log('########### Requesting remote notifications permission');
                            FirebasePlugin.grantPermission(checkNotificationPermission.bind(this, true));
                        } else {
                            // Denied
                            console.log("########### Notifications won't be shown as permission is denied");
                        }
                    });

                    FirebasePlugin.onMessageReceived(
                        function (message) {
                            try {
                                console.log('########### onMessageReceived');
                                console.log(JSON.stringify(message));
                                if (message.messageType === 'notification') {
                                    //handleNotificationMessage(message);
                                    if (message.tap) {
                                        console.log('########### Tapped in ' + message.tap);
                                        console.log('########### ' + JSON.stringify(message));
                                        if (app.booking_id > 0 && app.goto) {
                                            app.tab.show('#view-chat');
                                            app.goto = false;
                                        }
                                    } else {
                                        console.log('########### NOT Tapped in ');
                                    }
                                } else {
                                    //handleDataMessage(message);
                                }
                            } catch (e) {
                                console.log('########### Exception in onMessageReceived callback: ' + e.message);
                            }
                        },
                        function (error) {
                            console.log('########### Failed receiving FirebasePlugin message', error);
                        }
                    );

                    FirebasePlugin.onOpenSettings(
                        function () {
                            console.log('########### Redirect to App Notification Settings Page here');
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                }
            };

            /*async function pushAction() {
                if (typeof FirebasePlugin !== 'undefined' && (app.device.android || app.device.ios)) {
                    FirebasePlugin.onMessageReceived(function(message) {
                        console.log("Message type: " + message.messageType);
                        if(message.messageType === "notification"){
                            console.log("Notification message received");
                            if(message.tap){
                                console.log("Tapped in " + message.tap);
                                console.log(JSON.stringify(message));
                                if (app.booking_id > 0 && app.goto) {
                                    app.tab.show('#view-chat');
                                    app.goto = false;
                                }
                            }
                        }
                        console.log('#####################_pushAction');
                        console.dir(message);
                    }, function(error) {
                        console.error(error);
                    });
                }
            }*/

            checkNotificationPermission();
            //pushAction();
            document.addEventListener(
                'resume',
                function () {
                    //pushAction();
                },
                false
            );
            // push уведомления КОНЕЦ -->

            if (!localStorage.getItem('uuid')) {
                localStorage.setItem('uuid', uuidv4());
            }
            app.uuid = localStorage.getItem('uuid');

            var f7 = this;
            if (f7.device.cordova) {
                cordovaApp.init(f7);
            }

            app.reset_app = () => {
                app.request.post(`${root.value}?r=api/clearfcmtoken`, { booking_id: app.booking_id, pushToken: app.pushToken }).then(res2 => {
                    try {
                    } catch (e) {
                        return false;
                    }
                });
                localStorage.clear();
                localStorage.setItem('uuid', app.uuid); // UUID чтобы сохранялся
                app.store.dispatch('ClearRoom_fields');

                location.reload();
                /*if (app.booking_id) {
                    location.reload();
                }
                app.tab.show('#view-home'); // почему-то без этой строчки следующая не работает если нет активного бронирования
                app.views.main.router.navigate('/login/');*/
            };

            // функция для закрытия модального окна
            app.close_modal = () => {
                setTimeout(() => {
                    $$('.modal-popup').fadeOut();
                    $$('.modal-popup .info-text').hide();
                }, 1000);
            };

            // функция для получения ключа bluetooth
            app.get_bluetooth_key = () => {
                // получаем ключ bluetooth
                app.request.post(`${root.value}?r=api/getekeybybookingid`, { booking_id: app.booking_id }).then(res2 => {
                    try {
                        let v = JSON.parse(res2.data);
                        if (v['lockData'] && v['lockData'] !== app.lockData) {
                            localStorage.setItem('lockData', v['lockData']);
                            localStorage.setItem('lockMac', v['lockMac']);
                            app.lockData = v['lockData'];
                            app.lockMac = v['lockMac'];
                        }
                    } catch (e) {
                        return false;
                    }
                });
                // получаем ключ bluetooth -->
            };

            // функция для открытия замка через bluetooth
            if (typeof TTLock !== 'undefined') {
                if (app.device.android) {
                    TTLock.Lock.isBLEEnabled();
                }
                if (app.device.ios) {
                    TTLock.Lock.setupBluetooth();
                }
            }
            app.ble_open_lock = (t, mac, al = true) => {
                if (typeof TTLock !== 'undefined') {
                    try {
                        TTLock.Lock.isBLEEnabled().then(function (result) {
                            if (result) {
                                TTLock.Lock.control(
                                    TTLock.ControlAction.Unlock,
                                    t,
                                    mac,
                                    function (text) {
                                        if (localStorage.getItem('hotel_id') === '703') {
                                            app.request.post(`${root.value}?r=api/lock`, { booking_id_accept: localStorage.getItem('booking_id') });
                                        }
                                        app.close_modal();
                                    },
                                    function (text) {
                                        if (text?.message) {
                                            app.dialog.alert(text.message, 'error');
                                        }
                                        app.request.post(`${root.value}?r=api/lock`, {
                                            booking_id_error: localStorage.getItem('booking_id'),
                                            text: text,
                                            mac: mac,
                                            al: al,
                                        });
                                        app.close_modal();
                                    }
                                );
                                app.get_bluetooth_key();
                            } else {
                                app.close_modal();
                                app.dialog.alert('Доступ к Bluetooth запрещен. Зайдите в настройки приложения и разрешите использование Bluetooth.');
                            }
                        });
                    } catch (error) {
                        app.dialog.alert('Ошибка при проверке состояния Bluetooth:' + error);
                        app.close_modal();
                    }
                } else {
                    app.close_modal();
                    app.dialog.alert('Bluetooth не найден');
                }
            };

            //
            app.app_messages = div_class => {
                return app.messages.create({
                    el: '.messages' + div_class,
                    scrollMessagesOnEdge: false,

                    firstMessageRule: function (message, previousMessage, nextMessage) {
                        if (message.isTitle) return false;

                        return !previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name;
                    },
                    lastMessageRule: function (message, previousMessage, nextMessage) {
                        if (message.isTitle) return false;

                        return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
                    },
                    tailMessageRule: function (message, previousMessage, nextMessage) {
                        if (message.isTitle) return false;

                        return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
                    },
                });
            };

            //установить основной цвет приложения
            const set_hotel_main_color = async booking_id => {
                await app.request.post(`${root.value}?r=api/getcssconstantbybookingid`, { booking_id }).then(res => {
                    try {
                        // if (res.status === 200) {
                        // const result = JSON.parse(res.data);
                        // if (result.css_id) {
                        //     const body = document.querySelector('body');
                        //     body.style.setProperty('--f7-theme-color', result.css_id);
                        //     body.style.setProperty('--active-color', result.css_id);
                        //     body.style.setProperty('--swiper-theme-color', result.css_id);
                        // }
                        // }
                    } catch (e) {
                        console.log('Error:', e);
                    }
                });
            };
            if (app?.booking_id) {
                set_hotel_main_color(app?.booking_id);
            }
            (async () => {
                await app.store.dispatch('getBooking', { booking_id: app.booking_id, uuid: app.uuid });
                if (store.getters.roomNumber.value) {
                    app.roomNumber = store.getters.roomNumber.value;
                } else {
                    app.views.main.router.navigate('/login/', { reloadCurrent: true });
                }
            })();
            $(document).on('click', '.btn, .link, a', function (e) {
                if (app.device.android && navigator?.vibrate) {
                    navigator.vibrate(app.store.getters.programming_fields.value.vibro_time);
                }
            });
        },
        pageBeforeIn: function (e) {
            if (app.booking_id > 1) {
                if (e.name === 'management') {
                    app.store.dispatch('getHotelTabsObj');
                }
                if (e.name !== 'information') {
                    app.views.main.router.navigate('/room-management/');
                }
            }
        },
        pageAfterIn: function (e) {
            if (e.name === 'booking') {
                app.toolbar.hide('.toolbar', false);
            }
        },
        tabShow: function (e) {
            if (window.appMetrica && (app.device.android || app.device.ios)) {
                window.appMetrica.reportEvent('Переход на страницу', {
                    booking_id: app.booking_id,
                    uuid: app.uuid,
                    room_num: app.room_num,
                    page: $(e).data('name'),
                });
            }

            if ($(e).data('name') === 'home') {
                // так и не понял зачем вызывать этот метод при каждом переходе на вкладку home
                // app.store.dispatch('getHotelTabsObj');
            }

            if ($(e).data('name') === 'chat') {
                $('#view-chat .messagebar-area textarea').focus();
                $('#view-chat .messagebar-area textarea').blur();
                // $('.messages-content').scrollTop($$('.messages-content').get(0).scrollHeight, 300); // чтобы чат вниз пролистывался
                app.toolbar.hide('.toolbar', false);
            }
        },
    },
});
