/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  var user = $store.getters.user;
  var hotel_tabs_obj = $store.getters.hotel_tabs_obj;
  var language = $store.getters.language;
  $('.popup').on('popup:opened', function () {
    $('.loyalty-detail-img').addClass('fixed');
    $('.loyalty-detail-close').addClass('fixed');
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-toolbar no-swipeback no-navbar bg-color-white">
        <div class="page-content">
            <div class="loyalty-detail-close navbar">
                <div class="navbar-inner">
                    <a class="link popup-close" href="#">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="16" fill="black" fill-opacity="0.3" />
                            <path d="M20 20L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20 12L12 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
            </div>

            <div class="loyalty-detail-img">
                <img src=${text_obj.value.loyalty_bg_img} alt="" class="lazy lazy-fade-in demo-lazy" />
            </div>

             <div class="loyalty-detail-info">
                <div class="block">
                ${text_obj.value && text_obj.value.residence_history && <h2>{text_obj.value.residence_history}</h2>}
                    
                ${text_obj.value && text_obj.value.residence_history && user.value.residence_history
                    ?    
                    <div class="data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th class="first-cell">
                                        {text_obj.value.residence_history_table_header_1}
                                    </th>
                                    <th class="double-cell">
                                        {text_obj.value.residence_history_table_header_2}
                                    </th>
                                    <th class="double-cell">
                                        {text_obj.value.residence_history_table_header_3}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.value.residence_history.map(el=>el.map(ell=>
                                    <tr>
                                        <td>{ell.date_from}</td>
                                        <td>{ell.date_to}</td>
                                        <td>{language.value=='ru'?ell.room_num.hotel_name_ru:ell.room_num.hotel_name_en}</td>
                                    </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    :
                        $h` ${text_obj.value && <div>{text_obj.value.residence_history_not}</div>}`
                }
                </div>
             </div>
        </div>
    </div>    
`
    }
    ;
}
framework7Component.id = 'ef44384df7';
export default framework7Component;