/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on;
  var text_obj = $store.getters.text_obj;
  $on('pageInit', function () {
    var pickerQ = $f7.picker.create({
      inputEl: '#picker-q',
      cols: [{
        textAlign: 'center',
        values: [1, 2, 3, 4, 5, 6, 7, 8]
        //displayValues: ['1 шт.', '2 шт.', '3 шт.', '4 шт.', '5 шт.', '6 шт.', '7 шт.', '8 шт.'],
        /*onChange: function (picker, country) {
        picker.cols[0].setValue(5);
        }*/
      }]
      /*on: {
      change: function (picker, values, displayValues) {
      console.log(pickerQ);
      picker.cols[0].setValue(5);
      },
      }*/
    });

    $('.quantity').on('click', function () {
      $('#picker-q').click();
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="basket-shop">
        <div class="page-content">
            <div class="content-wrapper">
                <section class="header">
                    <div class="header-title">
                        <div class="title-shop title">${text_obj.value.your_basket}</div>
                        <a href="/basket-shop/">
                            <i class="icon f7-icons if-not-md">cart_fill</i>
                            <i class="icon material-icons md-only">shopping_cart</i>
                            <!--<i class="fa fa-shopping-cart" aria-hidden="true">
              </i>
              <div class="shopping-cart">1</div>-->
                        </a>
                    </div>
                </section>

                <section class="basket-shop">
                    <div class="basket-shop-block">
                        <div class="card-product">
                            <div class="img">
                                <img src="./static/shop-1.png" />
                            </div>
                            <div class="card-product-description">
                                <div class="name">Свитшот</div>
                                <div class="description">Кофта теплая (черная)</div>
                                <div class="prise">3150₽</div>
                            </div>
                            <div class="quantity display-flex">
                                Кол-во:<input type="text" readonly="readonly" style="width: 10px; margin: 0 0 0 5px" id="picker-q" value="1" /> шт

                                <!--<div class="list no-hairlines-md">
                  <ul>
                    <li>
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-input-wrap">
                            <input type="text" readonly="readonly" id="picker-q" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>-->

                                <a href="javascript:void(0);" id="collich">
                                    <img src="./static/svg/Swap.svg" />
                                </a>
                            </div>
                            <div class="delete-button">
                                <a href="javascript:void(0);">
                                    ${text_obj.value.delete_basket}
                                    <img src="./static/svg/Delete.svg" />
                                </a>
                            </div>
                        </div>
                        <script type="text/javascript"></script>
                        <div class="basket-shop-description">
                            <div class="col-1">
                                <div class="title-basket">${text_obj.value.your_basket}</div>
                                <div class="basket-quantity">1 товар</div>
                            </div>
                            <div class="col-2">
                                <div class="quantity">Товары (1)</div>
                                <div class="quantity-prise">3150₽</div>
                            </div>
                            <div class="col-3">
                                <div class="discount">Скидка</div>
                                <div class="discount-prise">-270₽</div>
                            </div>
                            <div class="col-4">
                                <div class="total-prise">Обшая стоимость</div>
                                <div class="prise">2880₽</div>
                            </div>
                            <div class="buy-button">
                                <a href="javascript:void(0);">Отправить заявку</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="top-menu">
                    <div class="top-menu-block">
                        <a href="/room-management/">
                            <div class="top-menu-block-item">
                                <div class="img">
                                    <i class="icon f7-icons if-not-md">house_fill</i>
                                    <i class="icon material-icons md-only">home</i>
                                </div>
                                <div class="title">${text_obj.value.my_number_basket}</div>
                            </div>
                        </a>
                        <a href="/chat/">
                            <div class="top-menu-block-item">
                                <div class="img">
                                    <i class="icon f7-icons if-not-md">chat_bubble_text_fill</i>
                                    <i class="icon material-icons md-only">chat</i>
                                </div>
                                <div class="title">${text_obj.value.chat_basket}</div>
                            </div>
                        </a>
                        <a href="/shop/">
                            <div class="top-menu-block-item active">
                                <div class="img">
                                    <i class="icon f7-icons if-not-md">checkmark_seal_fill</i>
                                    <i class="icon material-icons md-only">new_releases</i>
                                </div>
                                <div class="title">${text_obj.value.shop_basket}</div>
                            </div>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '67c41d44f3';
export default framework7Component;