/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $on = _ref.$on,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  var root = $store.getters.root;
  $on('pageInit', function () {
    $$('#submit_booking').on('submit', function (e) {
      e.preventDefault();
      $$('#booking_id').blur();
      $$('#booking_id_btn').click();
    });
    $$('#booking_ext_link_booking_btn').on('click', function () {
      if (window.appMetrica) {
        window.appMetrica.reportEvent('Переход на бронирование на сайте booking.com/', {
          uuid: $f7.uuid
        });
      }
    });
    $$('#booking_id_btn').on('click', function () {
      var booking_id = $$('#booking_id').val();
      var timestamp = Date.now();
      var count = 0;
      if (localStorage.getItem('booking_count')) {
        count = +localStorage.getItem('booking_count');
      }
      if (window.appMetrica && ($f7.device.android || $f7.device.ios)) {
        window.appMetrica.reportEvent('Ввод номера бронирования', {
          id: booking_id,
          uuid: $f7.uuid
        });
      }
      if (booking_id && (booking_id.length > 3 || booking_id == 21)) {
        if (count >= 3) {
          var booking_timestamp = +localStorage.getItem('booking_timestamp');
          if (booking_timestamp) {
            if (booking_timestamp <= timestamp) {
              count = 0;
              localStorage.setItem('booking_timestamp', ' ');
            }
          } else {
            booking_timestamp = timestamp + 300000; // +5 минут
            localStorage.setItem('booking_timestamp', booking_timestamp);
          }
        }
        if (count < 3) {
          count = count + 1;
          localStorage.setItem('booking_count', count);
          $f7.request.post("".concat(root.value, "?r=api/getbooking"), {
            booking_id: booking_id,
            uuid: $f7.uuid,
            count: count
          }).then(function (res) {
            var v = JSON.parse(res.data);
            if (v['v']) {
              localStorage.setItem('room_num', v['v']);
              localStorage.setItem('booking_id', booking_id);
              localStorage.setItem('hotel_id', v['hotel_id']);
              $f7.room_num = v['v'];
              $f7.booking_id = booking_id;
              // получаем ключ bluetooth
              $f7.request.post("".concat(root.value, "?r=api/getekeybybookingid"), {
                booking_id: booking_id
              }).then(function (res2) {
                var v = JSON.parse(res2.data);
                if (v['lockData']) {
                  localStorage.setItem('lockData', v['lockData']);
                  localStorage.setItem('lockMac', v['lockMac']);
                  $f7.lockData = v['lockData'];
                  $f7.lockMac = v['lockMac'];
                  location.reload();
                } else {
                  $f7.dialog.alert("".concat(text_obj.value.booking_text_invalid));
                }
              }).catch(function () {
                $f7.dialog.alert("".concat(text_obj.value.booking_text_error_connect));
              });
              // получаем ключ bluetooth -->
              //location.reload();
            } else {
              $f7.dialog.alert("".concat(text_obj.value.booking_text_invalid));
            }
          }).catch(function () {
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_connect));
          });
        } else {
          var _booking_timestamp = +localStorage.getItem('booking_timestamp');
          if (_booking_timestamp) {
            var showTimer = new Date(_booking_timestamp);
            var pad = function pad(num) {
              return ('0' + num).slice(-2);
            };
            var showTimerFormat = pad(showTimer.getHours()) + ':' + pad(showTimer.getMinutes()) + ':' + pad(showTimer.getSeconds());
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_time, " ").concat(showTimerFormat));
          } else {
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_time_2));
          }
        }
      } else {
        $f7.dialog.alert("".concat(text_obj.value.booking_text));
      }
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="booking">
        <div class="page-content booking-page display-flex align-items-center">
            <section class="form-index display-flex justify-content-center">
                <img data-src="./static/Saly-14.png" class="lazy" />

                <div class="booking-form-description">${text_obj.value.booking_text}</div>
                <form class="booking-form" id="submit_booking">
                    <div class="item-input-wrap">
                        <input type="number" name="booking" id="booking_id" value="${$f7.booking_id}" autocomplete="off"
                            placeholder="${text_obj.value.booking_text_1}" />
                    </div>
                    <a class="form-button" id="booking_id_btn" href="">${text_obj.value.booking_next}</a>
                </form>
                <a class="link external button button-outline margin-top" id="booking_ext_link_booking_btn"
                    href="https://www.booking.com/" target="_system">${text_obj.value.booking_room}</a>
                <!-- ссылка на приложение -->
                <a class="link external update_app_link"
                    href="https://apps.apple.com/ru/app/mys-hotel/id6462056566" target="_system">update</a>
            </section>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = 'a5e1dedffd';
export default framework7Component;