import HomePage from '../pages/home.f7.html';
import BookingPage from '../pages/booking.f7.html';
import RoomManagementPage from '../pages/room-management.f7.html';
import ChatPage from '../pages/chat.f7.html';
import ChatPageDynamic from '../pages/chat-dynamic.f7.html';

import FoodPage from '../pages/food.f7.html';

import InformationPage from '../pages/information.f7.html';
import InformationContacts from '../pages/information/contacts.f7.html';
import InformationAbout from '../pages/information/about.f7.html';
import InformationLobby from '../pages/information/lobby.f7.html';
import InformationBar from '../pages/information/bar.f7.html';
import InformationSpa from '../pages/information/spa.f7.html';
import InformationWiFi from '../pages/information/wi-fi.f7.html';
import InformationLocation from '../pages/information/location.f7.html';
import InformationActivity from '../pages/information/activity.f7.html';
import Feedback from '../pages/feedback.f7.html';
import InformationRooms from '../pages/information/rooms.f7.html';
import InformationRoomDynamic from '../pages/information/room-dynamic.f7.html';
import InformationLocationDynamic from '../pages/information/location-dynamic.f7.html';
import InformationContent from '../pages/information/content.f7.html';
import InformationContentDynamic from '../pages/information/content-dynamic.f7.html';

import ShopPage from '../pages/shop.f7.html';
import ShopDetailPage from '../pages/shop-detail.f7.html';
import BasketPage from '../pages/basket-shop.f7.html';

import ServiceMainLight from '../pages/services/main_light.f7.html';
import ServiceTV1 from '../pages/services/tv_1.f7.html';
import ServiceTV2 from '../pages/services/tv_2.f7.html';
import ServiceLent from '../pages/services/lent.f7.html';
import ServiceHumidifier from '../pages/services/humidifier.f7.html';

import LoginPage from '../pages/login/main.f7.html';
import AuthByCodePage from '../pages/login/auth-by-code.f7.html';
import AuthByPhonePage from '../pages/login/auth-by-phone.f7.html';
import AuthByFullName from '../pages/login/auth_by_fio.f7.html';

import ProfilePage from '../pages/profile.f7.html';
import ProfileEditPage from '../pages/profile-edit.f7.html';
import LoyaltyPage from '../pages/loyality.f7.html';
import ResidenceHistory from '../pages/residence-history.f7.html';

// coworking
import OfficePage from '../pages/coworking/office.f7.html';
import TariffListPage from '../pages/coworking/tariff-list.f7.html';
import TariffPage from '../pages/coworking/tariff-item.f7.html';
import TariffHistory from '../pages/coworking/tariff-history.f7.html';

import NotFoundPage from '../pages/404.f7.html';

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/booking/',
        component: BookingPage,
        options: {
            animate: false,
        },
        on: {
            pageAfterIn(e, page) {
                page.router.clearPreviousHistory();
            },
        },
    },
    {
        path: '/login/',
        component: LoginPage,
        options: {
            animate: false,
        },
    },
    {
        path: '/auth-by-code/',
        component: AuthByCodePage,
    },
    {
        path: '/auth-by-phone/',
        component: AuthByPhonePage,
    },
    {
        path: '/auth-by-fio/',
        component: AuthByFullName,
    },
    {
        path: '/room-management/',
        component: RoomManagementPage,
        options: {
            animate: false,
        },
    },
    {
        path: '/chat/',
        component: ChatPage,
        options: {
            animate: false,
        },
    },
    {
        path: '/chat-dynamic/:taskId/',
        component: ChatPageDynamic,
        options: {
            openIn: 'popup',
        },
    },
    {
        path: '/food/:food_id/',
        component: FoodPage,
        options: {
            animate: false,
        },
    },
    {
        path: '/information/',
        component: InformationPage,
        options: {
            animate: false,
        },
        routes: [
            {
                path: 'contacts/',
                component: InformationContacts,
                props: {
                    id: 'contacts',
                },
            },
            {
                path: 'about/',
                component: InformationAbout,
            },
            {
                path: 'lobby/',
                component: InformationLobby,
            },
            {
                path: 'bar/',
                component: InformationBar,
            },
            {
                path: 'spa/',
                component: InformationSpa,
            },
            {
                path: 'wi-fi/',
                component: InformationWiFi,
            },
            {
                path: 'location/',
                component: InformationLocation,
                routes: [
                    {
                        path: ':locationId/',
                        component: InformationLocationDynamic,
                    },
                ],
            },
            {
                path: 'activity/',
                component: InformationActivity,
            },
            {
                path: 'feedback/',
                component: Feedback,
            },
            {
                path: 'rooms/',
                component: InformationRooms,
                routes: [
                    {
                        path: ':roomId/',
                        component: InformationRoomDynamic,
                    },
                ],
            },
            {
                path: 'content/',
                component: InformationContent,
                routes: [
                    {
                        path: ':contentId/',
                        component: InformationContentDynamic,
                    },
                ],
            },
        ],
    },

    {
        path: '/shop/',
        component: ShopPage,
        options: {
            animate: false,
        },
        routes: [
            {
                path: ':categoryId/:shopItemId/',
                component: ShopDetailPage,
            },
        ],
    },
    {
        path: '/basket-shop/',
        component: BasketPage,
        options: {
            animate: false,
        },
    },
    {
        path: '/services/',
        routes: [
            {
                path: 'main-light/',
                component: ServiceMainLight,
            },
            {
                path: 'tv-1/',
                component: ServiceTV1,
            },
            {
                path: 'tv-2/',
                component: ServiceTV2,
            },
            {
                path: 'lent/',
                component: ServiceLent,
            },
            {
                path: 'humidifier/',
                component: ServiceHumidifier,
            },
        ],
    },
    {
        path: '/profile/',
        component: ProfilePage,
    },
    {
        path: '/profile-edit/',
        component: ProfileEditPage,
    },
    {
        path: '/loyalty/',
        component: LoyaltyPage,
        options: {
            openIn: 'popup',
        },
    },
    {
        path: '/residence-history/',
        component: ResidenceHistory,
        options: {
            openIn: 'popup',
        },
    },
    {
        path: '/coworking/',
        routes: [
            {
                path: 'office/',
                component: OfficePage,
            },
            {
                path: 'tariff-list/',
                component: TariffListPage,
            },
            {
                path: 'tariff/:id/',
                component: TariffPage,
            },
            {
                path: 'tariff-history/',
                component: TariffHistory,
            },
        ],
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;
