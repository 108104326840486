/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  var text_obj = $store.getters.text_obj;
  var root = $store.getters.root;
  $on('pageAfterIn', function () {
    $$('#booking_code').focus();
  });
  $on('pageInit', function () {
    $$('#booking_code').on('input', function (e) {
      var val = e.target.value;
      if (val.length > 0) {
        var formattedVal = val.replace(/\D/g, '');
        $$('#booking_code').val(formattedVal);
      }
    });
    $$('#submit_booking').on('submit', function (e) {
      e.preventDefault();
      $$('#booking_code').blur();
      $$('#booking_id_btn').click();
    });
    $$('#booking_id_btn').on('click', function (e) {
      var booking_id = $$('#booking_code').val();
      if (booking_id.length < 3) {
        e.preventDefault();
        $f7.dialog.alert("".concat(text_obj.value.booking_text));
        return false;
      }
      var timestamp = Date.now();
      var count = 0;
      if (localStorage.getItem('booking_count')) {
        count = +localStorage.getItem('booking_count');
      }
      if (window.appMetrica && ($f7.device.android || $f7.device.ios)) {
        window.appMetrica.reportEvent('Ввод номера бронирования', {
          id: booking_id,
          uuid: $f7.uuid
        });
      }
      if (booking_id && booking_id.length > 3) {
        if (count >= 3) {
          var booking_timestamp = +localStorage.getItem('booking_timestamp');
          if (booking_timestamp) {
            if (booking_timestamp <= timestamp) {
              count = 0;
              localStorage.setItem('booking_timestamp', ' ');
            }
          } else {
            booking_timestamp = timestamp + 300000; // +5 минут
            localStorage.setItem('booking_timestamp', booking_timestamp);
          }
        }
        if (count < 3) {
          count = count + 1;
          localStorage.setItem('booking_count', count);
          $f7.request.post("".concat(root.value, "?r=api/getbooking"), {
            booking_id: booking_id,
            uuid: $f7.uuid,
            count: count
          }).then(function (res) {
            var v = JSON.parse(res.data);
            if (v['v']) {
              localStorage.setItem('room_num', v['v']);
              localStorage.setItem('booking_id', booking_id);
              localStorage.setItem('hotel_id', v['hotel_id']);
              localStorage.setItem('method', '0');
              $f7.room_num = v['v'];
              $f7.booking_id = booking_id;
              $f7.method = '0';

              // проверка авторизации из вэба
              if (window.location.href.includes('vertical') && $f7.booking_id) {
                var data = {
                  hotel_id: $f7.hotel_id,
                  booking_id: $f7.booking_id,
                  method: $f7.method
                };
                $store.dispatch('checkMobileOrNot', data);
              }
              // получаем ключ bluetooth
              $f7.request.post("".concat(root.value, "?r=api/getekeybybookingid"), {
                booking_id: booking_id
              }).then(function (res2) {
                var v = JSON.parse(res2.data);
                if (v['lockData']) {
                  localStorage.setItem('lockData', v['lockData']);
                  localStorage.setItem('lockMac', v['lockMac']);
                  $f7.lockData = v['lockData'];
                  $f7.lockMac = v['lockMac'];
                  location.reload();
                } else {
                  $f7.dialog.alert("".concat(text_obj.value.booking_text_invalid));
                }
              }).catch(function () {
                $f7.dialog.alert("".concat(text_obj.value.booking_text_error_connect));
              });
              // получаем ключ bluetooth -->
              // location.reload();
            } else {
              $f7.dialog.alert("".concat(text_obj.value.booking_text_invalid));
            }
          }).catch(function () {
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_connect));
          });
        } else {
          var _booking_timestamp = +localStorage.getItem('booking_timestamp');
          if (_booking_timestamp) {
            var showTimer = new Date(_booking_timestamp);
            var pad = function pad(num) {
              return ('0' + num).slice(-2);
            };
            var showTimerFormat = pad(showTimer.getHours()) + ':' + pad(showTimer.getMinutes()) + ':' + pad(showTimer.getSeconds());
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_time, " ").concat(showTimerFormat));
          } else {
            $f7.dialog.alert("".concat(text_obj.value.booking_text_error_time_2));
          }
        }
      } else {
        $f7.dialog.alert("".concat(text_obj.value.booking_text));
      }
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-toolbar no-swipeback bg-color-white">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 20L0 10L10 0L11.775 1.775L3.55 10L11.775 18.225L10 20Z" fill="#1C1B1F" />
                        </svg>
                    </a>
                </div>
                <div class="title">${text_obj.value.booking_text_2}</div>
            </div>
        </div>
        <div class="page-content login-content">
            <div class="block description-block">
                <div class="title">${text_obj.value.booking_text_3}</div>
                <div class="subtitle">${text_obj.value.booking_text_4}</div>
            </div>

            <form class="form-block" id="submit_booking">
                <div class="block no-margin item-content item-input item-input-with-info">
                    <div class="item-input-wrap">
                        <input type="number" inputmode="decimal" name="booking_code" id="booking_code" value="${$f7.booking_id}" autocomplete="off" />
                        <span class="input-clear-button"></span>
                    </div>
                </div>

                <div class="buttons">
                    <div class="block no-margin">
                        <button class="btn" id="booking_id_btn" type="button" name="submit">${text_obj.value.booking_next}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '928a4739a1';
export default framework7Component;