/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  var services = $store.getters.services;
  var text_obj = $store.getters.text_obj;
  var changeService = function changeService(type, value) {
    $store.dispatch('setService', {
      type: type,
      value: value
    });
  };
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-toolbar">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
                <div class="left">
                    <a href="#" class="link back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7903 4.3871L10.7071 4.29289C10.3466 3.93241 9.77939 3.90468 9.3871 4.2097L9.29289 4.29289L2.29289 11.2929L2.2515 11.3369L2.19633 11.4047L2.12467 11.5159L2.07123 11.6287L2.03585 11.734L2.00691 11.8819L2 12L2.00279 12.0752L2.02024 12.2007L2.04974 12.3121L2.09367 12.4232L2.146 12.5207L2.21969 12.6254L2.29289 12.7071L9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071C11.0676 19.3466 11.0953 18.7794 10.7903 18.3871L10.7071 18.2929L5.416 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H5.414L10.7071 5.70711C11.0676 5.34662 11.0953 4.77939 10.7903 4.3871L10.7071 4.29289L10.7903 4.3871Z" fill="black"/>
                        </svg>
                    </a>
                </div>
                <div class="title">${text_obj.value.my_room_tv_2}</div>
            </div>
        </div>
        <div class="page-content">
            <div class="block">
                <div class="tv-block">
                    <div class="tv-row">
                        <button
                                type="button"
                                class="red"
                                @click=${()=>changeService('tv_2', !services.value.tv_2)}
                        >
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4898 1.91657C11.3638 1.91781 11.2393 1.94398 11.1234 1.99361C11.0074 2.04325 10.9024 2.11538 10.8142 2.20584C10.7261 2.2963 10.6566 2.40339 10.6096 2.52087C10.5627 2.63835 10.5393 2.76393 10.5408 2.8905V10.5937C10.5409 10.8492 10.642 11.0942 10.8219 11.2749C11.0018 11.4555 11.2458 11.557 11.5001 11.557C11.7545 11.557 11.9985 11.4555 12.1784 11.2749C12.3583 11.0942 12.4594 10.8492 12.4595 10.5937V2.8905C12.461 2.76219 12.4369 2.63485 12.3887 2.51601C12.3405 2.39717 12.2692 2.28918 12.1788 2.19844C12.0885 2.1077 11.981 2.03606 11.8627 1.98764C11.7444 1.93923 11.6176 1.91507 11.4898 1.91657ZM17.0813 3.84753C17.0501 3.84662 17.0188 3.84725 16.9877 3.8494C16.7982 3.86556 16.6178 3.93799 16.4694 4.05743C16.321 4.17687 16.2113 4.33791 16.1543 4.52014C16.0973 4.70236 16.0956 4.89755 16.1493 5.08078C16.203 5.264 16.3098 5.42702 16.4561 5.54912C18.1163 6.96519 19.1666 9.07287 19.1666 11.4397C19.1666 15.7133 15.7501 19.1579 11.5048 19.1579C7.25957 19.1579 3.83552 15.7133 3.83553 11.4397C3.83554 9.08656 4.87252 6.9932 6.51613 5.5773C6.61189 5.49521 6.69061 5.39494 6.7478 5.28228C6.80498 5.16963 6.83952 5.04678 6.84943 4.92072C6.85934 4.79466 6.84444 4.66793 6.80556 4.54766C6.76669 4.42739 6.70461 4.31599 6.62288 4.21981C6.54114 4.12364 6.44134 4.04457 6.32918 3.98713C6.21702 3.9297 6.09469 3.89495 5.96918 3.885C5.84368 3.87505 5.71745 3.89005 5.59771 3.9291C5.47797 3.96815 5.36705 4.03049 5.2713 4.11259C3.21956 5.88008 1.91682 8.51461 1.91681 11.4397C1.9168 16.7521 6.22192 21.0832 11.5048 21.0832C16.7877 21.0832 21.0835 16.7521 21.0835 11.4397C21.0835 8.49755 19.7679 5.85218 17.6953 4.0844C17.5248 3.93518 17.3074 3.85128 17.0813 3.84753H17.0813Z" fill="white"/>
                            </svg>
                        </button>
                        <a class="tv-link" href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2H7C7.55228 2 8 2.44772 8 3C8 3.51284 7.61396 3.93551 7.11662 3.99327L7 4H5C4.48716 4 4.06449 4.38604 4.00673 4.88338L4 5V19C4 19.5128 4.38604 19.9355 4.88338 19.9933L5 20H19C19.5128 20 19.9355 19.614 19.9933 19.1166L20 19V17C20 16.4477 20.4477 16 21 16C21.5128 16 21.9355 16.386 21.9933 16.8834L22 17V19C22 20.5977 20.7511 21.9037 19.1763 21.9949L19 22H5C3.40232 22 2.09634 20.7511 2.00509 19.1763L2 19V5C2 3.40232 3.24892 2.09634 4.82373 2.00509L5 2H7H5ZM21 2L21.081 2.003L21.2007 2.02024L21.3121 2.04974L21.4232 2.09367L21.5207 2.146L21.6167 2.21279L21.7071 2.29289L21.8037 2.40469L21.8753 2.51594L21.9063 2.5769L21.9401 2.65835L21.9642 2.73401L21.9931 2.8819L22 3V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.414L13.7071 11.7071C13.3466 12.0676 12.7794 12.0953 12.3871 11.7903L12.2929 11.7071C11.9324 11.3466 11.9047 10.7794 12.2097 10.3871L12.2929 10.2929L18.584 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H21Z" fill="#1C2F30"/>
                            </svg>
                            ${text_obj.value.source_tv}
                        </a>
                        <button type="button">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.57605 7.09566C1.23011 7.09566 0.958374 7.36328 0.958374 7.70922V15.1213C0.958374 15.4672 1.23011 15.739 1.57605 15.739H5.98214V7.09566H1.57605ZM14.3084 2.39721C14.1108 2.28599 13.8637 2.29834 13.6784 2.40948L7.21749 6.57261V16.2702L13.666 20.5815C13.875 20.6976 14.0836 20.7186 14.3084 20.6063C14.5061 20.4951 14.6296 20.2851 14.6296 20.0627V2.92841C14.6296 2.70605 14.5061 2.49599 14.3084 2.39721Z" fill="#1C2F30"/>
                                <path d="M19.8267 11.4153L21.8607 9.38129C22.102 9.14002 22.102 8.74912 21.8607 8.50785C21.6194 8.26659 21.2285 8.26659 20.9873 8.50785L18.9533 10.5419L16.9193 8.50785C16.678 8.26659 16.2871 8.26659 16.0458 8.50785C15.8046 8.74912 15.8046 9.14002 16.0458 9.38129L18.0798 11.4153L16.0458 13.4493C15.8046 13.6906 15.8046 14.0815 16.0458 14.3227C16.2871 14.564 16.6779 14.564 16.9193 14.3227L18.9533 12.2887L20.9873 14.3227C21.2285 14.564 21.6194 14.564 21.8607 14.3227C22.102 14.0815 22.102 13.6906 21.8607 13.4493L19.8267 11.4153Z" fill="#1C2F30"/>
                            </svg>
                        </button>
                    </div>

                    <div class="tv-row numbers-row">
                        <div class="dark">
                            <button type="button">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99975 15.0998C2.99975 14.8694 3.09231 14.639 3.27647 14.4635L10.832 7.26343C11.2013 6.91153 11.7982 6.91153 12.1675 7.26343L19.723 14.4635C20.0923 14.8154 20.0923 15.3842 19.723 15.7361C19.3538 16.088 18.7569 16.088 18.3876 15.7361L11.4998 9.17234L4.61192 15.7361C4.24264 16.088 3.64575 16.088 3.27647 15.7361C3.09231 15.5606 2.99975 15.3302 2.99975 15.0998" fill="white"/>
                                </svg>
                            </button>
                            <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.320312 20H2.78125V12.959H7.34766C11.0527 12.959 13.6777 10.3477 13.6777 6.60156V6.57422C13.6777 2.82812 11.0527 0.271484 7.34766 0.271484H0.320312V20ZM6.71875 2.45898C9.53516 2.45898 11.1621 4.00391 11.1621 6.60156V6.62891C11.1621 9.22656 9.53516 10.7715 6.71875 10.7715H2.78125V2.45898H6.71875Z" fill="white"/>
                            </svg>
                            <button type="button">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 7.90023C20.0002 8.13063 19.9077 8.36103 19.7235 8.53653L12.168 15.7366C11.7987 16.0885 11.2018 16.0885 10.8325 15.7366L3.27696 8.53653C2.90768 8.18463 2.90768 7.61583 3.27696 7.26393C3.64624 6.91202 4.24313 6.91202 4.6124 7.26393L11.5002 13.8277L18.3881 7.26393C18.7574 6.91202 19.3542 6.91202 19.7235 7.26393C19.9077 7.43943 20.0002 7.66983 20.0002 7.90023" fill="white"/>
                                </svg>
                            </button>
                        </div>

                        <div class="dark">
                            <button type="button">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 3.83331C12.0293 3.83331 12.4583 4.26237 12.4583 4.79165V10.5416H18.2083C18.7376 10.5416 19.1666 10.9707 19.1666 11.5C19.1666 12.0293 18.7376 12.4583 18.2083 12.4583H12.4583V18.2083C12.4583 18.7376 12.0293 19.1666 11.5 19.1666C10.9707 19.1666 10.5416 18.7376 10.5416 18.2083V12.4583H4.79165C4.26237 12.4583 3.83331 12.0293 3.83331 11.5C3.83331 10.9707 4.26237 10.5416 4.79165 10.5416H10.5416V4.79165C10.5416 4.26237 10.9707 3.83331 11.5 3.83331Z" fill="white"/>
                                </svg>
                            </button>
                            <svg width="45" height="18" viewBox="0 0 45 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 18L45 0V18H0Z" fill="white"/>
                            </svg>
                            <button type="button">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79165 10.5417H18.2083C18.7376 10.5417 19.1666 10.9707 19.1666 11.5C19.1666 12.0293 18.7376 12.4584 18.2083 12.4584H4.79165C4.26237 12.4584 3.83331 12.0293 3.83331 11.5C3.83331 10.9707 4.26237 10.5417 4.79165 10.5417Z" fill="white"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="tv-row numbers-row">
                        <button type="button">1</button>
                        <button type="button">2</button>
                        <button type="button">3</button>
                    </div>
                    <div class="tv-row numbers-row">
                        <button type="button">4</button>
                        <button type="button">5</button>
                        <button type="button">6</button>
                    </div>
                    <div class="tv-row numbers-row">
                        <button type="button">7</button>
                        <button type="button">8</button>
                        <button type="button">9</button>
                    </div>
                    <div class="tv-row numbers-row" style="justify-content: space-around">
                        <button type="button">0</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '52770d5df2';
export default framework7Component;