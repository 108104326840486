window.localization = {
    ru: {
        language: 'Russian',

        //служебные
        system_old_version: 'Приложение устарело',
        system_old_version_text: 'У Вас установлена старая версия приложения. Обновить?',

        // Главная
        main_1: 'Добро пожаловать в',
        main_2: 'MYS Boutique Hotel',
        main_3: 'Войти по номеру телефона',
        main_4: 'Войти по коду бронирования',
        main_5: 'Войти по личным данным',
        main_6: 'Авторизоваться',
        main_7: 'Выберите удобный для Вас способ авторизации',
        main_8: 'Авторизовываясь в приложении вы принимаете условия использования и ',
        main_9: 'Забронировать номер',
        main_10: 'политику конфиденциальности',

        // Войти по номеру телефона
        phone_1: 'Войти по номеру телефона',
        phone_2: 'Ваш номер телефона',
        phone_3: 'Пожалуйста, совершите дозвон с вашего телефона',
        phone_4: 'Отвечать на звонок не нужно.',
        phone_5: 'Позвонить',
        phone_6: 'Введите последние 4 цифры',
        phone_7: 'входящего номера',
        phone_8: 'Подтвердить',
        phone_9: 'Запросить новый номер',
        phone_10: 'Все номера для дозвона заняты, повторите попытку нажав на кнопку "Запросить новый номер"',
        phone_11: 'Запросите новый номер для дозвона нажав на кнопку "Запросить новый номер"',
        phone_12: 'Вероятно, Вы не оставили свой номер телефона',
        phone_13: '<br/>Попросите, пожалуйста, сотрудников ресепшен внести его в систему и повторите попытку',
        phone_14: 'Вашего номера нет в системе, попробуйте другой метод авторизации или обратитесь на ресепшен',
        phone_15: 'Для повторного запроса номера осталось:',

        // Войти по ФИО
        fio_text_1: 'Введите ваши данные',
        fio_text_2: 'Фамилия',
        fio_text_3: 'Имя',
        fio_text_4: 'Номер комнаты',
        fio_text_5: 'Введённые данные не корректны. Попробуйте другой метод авторизации или обратитесь на ресепшен',

        //Навигация
        nav_home: 'Мой номер',
        nav_chat: 'Чат',
        nav_information: 'Информация',
        nav_food: 'Еда в номер',
        nav_food2: 'Пицца',
        nav_shop: 'Услуги',

        // Страница Управление номером
        my_room_title: 'Управление номером',
        my_room_open_door: 'Открыть дверь',
        my_room_your_room: 'Ваш номер',
        my_room_conditioner: 'Кондиционер',
        my_manage_warm_floor: 'Тёплый пол',
        my_room_main_light: 'Основной свет',
        my_room_turn_on: 'Включить',
        my_room_turn_off: 'Выключить',
        my_room_open_off: 'Закрыть',
        my_room_open_on: 'Открыть',
        my_room_lent: 'Светодиодная лента',
        my_room_tv_1: 'TV в переговорной №1',
        my_room_tv_2: 'TV в переговорной №2',
        source_tv: 'Источник',
        my_room_humidifier: 'Увлажнитель воздуха',
        humidifier_mode_auto: 'Авто',
        humidifier_mode_1: 'Режим 1',
        humidifier_mode_2: 'Режим 2',
        humidifier_mode_3: 'Режим 3',
        my_room_coffee_machine: 'Умная кофеварка',
        my_room_tea_machine: 'Умный чайник',
        my_room_floor_lamp: 'Торшер',
        menage_error: 'Ошибка управления устройством',
        my_bathroom_light: 'Свет в ванной',
        my_room_illumination: 'Подсветка',
        my_room_curtains_right: 'Шторы правые',
        my_room_curtains_left: 'Шторы левые',

        // Страница управления светом
        main_light_east: 'Восточная',
        main_light_south: 'Южная',
        main_hall_1: 'Холл',
        main_hall_2: 'Холл 2',
        administrator_v: 'Администратор',
        zig_zag: 'Зиг-заг',
        premises_1: 'Помещение 1',
        premises_2: 'Помещение 2',

        // страница ошибки 404
        back_404: 'Назад',
        not_found_404: 'Не найдено',
        sorry_404: 'Извините',
        request_not_found_404: 'Запрошенный контент не найден.',

        // Страница Чат
        chat_title: 'Чат',
        chat_yes: 'Да',
        chat_no: 'Нет',
        chat_add_task: 'Добавить задачу',
        chat_message: 'Сообщение',
        chat_kaizen_1: 'Техническая эксплуатация',
        chat_kaizen_1_1: 'Не работает кондиционер',
        chat_kaizen_1_2: 'Вопрос по работе ТВ',
        chat_kaizen_1_3: 'Не работает освещение',
        chat_kaizen_1_4: 'Вопрос по сан технике',
        chat_kaizen_1_5: 'Не работает оборудование',
        chat_kaizen_2: 'Клининг',
        chat_kaizen_2_1: 'Мой номер не убран',
        chat_kaizen_2_2: 'Доп. полотенца',
        chat_kaizen_2_3: 'Доп. постельное белье',
        chat_kaizen_2_4: 'Доп. косметика',
        chat_kaizen_2_5: 'Принести утюг и гладильную доску',
        chat_kaizen_2_6: 'Провести уборку',
        chat_wi_fi: 'Wi-Fi',
        chat_wi_fi_login: 'Логин',
        chat_wi_fi_password: 'Пароль',
        chat_kaizen_created: 'Создана',
        chat_kaizen_canceled: 'Отменена',
        chat_kaizen_task_status_1: 'Отложена',
        chat_kaizen_task_status_8: 'Выполнена',
        chat_kaizen_task_status_5: 'Завершена',
        chat_kaizen_comments: 'Комментарии',
        chat_kaizen_error_2: 'Ошибка подключения 2',
        chat_kaizen_hello_text: 'Привет! Мы искренне рады, что вы с нами. Возникли какие-либо вопросы? Мы вам поможем.',

        //Страница Чат 2
        chat_2_title: 'Переписка по задаче',

        //Страница Еда в номер
        food_title: 'Еда в номер',
        food_title2: 'Пиццерия',
        food_your_order: 'Ваш заказ',
        food_order: 'Заказать',
        food_no_goods_in_category: 'Нет товаров в данной категории',
        food_thank_for_order: 'Спасибо за заказ!',
        food_checkout: 'Оформить заказ',
        food_opening_hours: 'Бар открыт с ',
        food_opening_hours_2: 'до ',
        food_pay: 'Оплатить',

        //Страница Информация
        information_title: 'Информация',
        information_information: 'Информация',
        information_hotel_rooms: 'Номера отеля',
        information_contacts: 'Контакты отеля',
        information_about: 'Об отеле',
        information_entertainment: 'Развлечения',
        information_events: 'Мероприятия',
        information_near_us: 'Рядом с нами',
        information_services: 'Услуги',
        information_lobby_bar: 'Лобби-бар',
        information_attic_bar: 'Бар на мансарде',
        information_spa: 'SPA',
        information_wi_fi: 'Wi-Fi',
        information_settings: 'Настройки',
        information_language: 'Язык',
        information_language_ru: 'Русский',
        information_language_en: 'English',
        information_log_out: 'Выход',
        information_close: 'Закрыть',
        information_call_reseption: 'Позвонить на ресепшн',

        // страница мерч
        shop_title: 'Магазин',
        shop_basket_empty: 'Корзина пуста',
        shop_amount: 'Кол-во:',
        shop_buy: 'Купить',
        shop_no_info: 'Информация отсутствует',

        // страница basket-shop
        your_basket: 'Ваша корзина',
        delete_basket: 'Удалить',
        my_number_basket: 'Мой номер',
        chat_basket: 'Чат',
        shop_basket: 'Магазин',
        send_mess_basket: 'Отправить заявку',

        //Страница Номера
        rooms_title: 'Номера отеля',
        rooms_room_size: 'Площадь номера:',
        rooms_room_1: 'Стандарт одноместный',
        rooms_room_2: 'Стандарт с двуспальной кроватью',
        rooms_room_3: 'Стандарт с двумя кроватями',
        rooms_room_4: 'Делюкс',

        //Страница Контакты
        contacts_title: 'Контакты отеля',
        contacts_address_title: 'Адрес:',
        contacts_address_description: 'г. Москва, ул. Малые Каменщики, 16',
        contacts_contacts_title: 'Контакты',
        contacts_contacts_phone: 'Тел:',

        //Страница Мероприятия
        event_title: 'Мероприятия',
        event_1_title: 'Мозгобойня',
        event_1_location: '2 этаж',
        event_1_date: '1 марта 17:00',
        event_2_title: 'Покер',
        event_2_location: '2 этаж',
        event_2_date: '12 марта 12:00',
        event_3_title: 'Китайское чаепитие',
        event_3_location: '2 этаж',
        event_3_date: '22 марта 17:00',

        //Страница Об отеле
        about_title: 'Об отеле',
        about_description_title: 'Proptech Solutions \n BW Digital Concierge',
        about_description_content:
            'Камерный отель в стиле лофт, который предлагает новый формат путешествий для современных странников. Отель имеет сертификат соответствия категории 4* и работает по международным стандартам Best Western.',

        //Страница Рядом с нами
        near_us_title: 'Рядом с нами',
        near_us_1_title: 'Новоспасский монастырь',
        near_us_1_address: 'Крестьянская площадь, д. 10, ст. 12',
        near_us_1_time: '11 мин',
        near_us_2_title: 'Музей Бункер 42',
        near_us_2_address: '5-й Котельнический переулок, д. 11',
        near_us_2_time: '12 мин',
        near_us_3_title: 'Московский международный Дом музыки',
        near_us_3_address: 'Космодамианская наб., д. 52, стр. 8',
        near_us_3_time: '16 мин',

        //Страница Лобби бар
        lobby_title: 'Лобби',
        lobby_location: '2 этаж, ресепшн',
        lobby_time: 'Круглосуточно',
        lobby_description: 'К услугам гостей на 2 этаже находится круглосуточный ресепшн. Просто сообщите нам ваши пожелания и мы исполним все в лучшем виде.',

        //Страница Lounge-bar на мансарде
        bar_title: 'Lounge-bar на мансарде',
        bar_location: 'Мансарда',
        bar_time: 'с 7:30 до 22:00',
        bar_description:
            'Утром вы можете насладиться вкусным завтраком в формате «шведский стол», обсудить планы на день с единомышленниками, а вечер провести в приятной компании на мансарде под музыку локальных групп.',

        //Страница SPA
        spa_title: 'SPA',
        spa_location: 'Мансарда',
        spa_time: 'скоро открытие',
        spa_description:
            'Попав в нашу СПА-зону на мансардном этаже, вы окунетесь в восхитительную романтическую атмосферу. Только представьте, как мягко мерцающие свечи, лаунж-музыка и исключительный аромат создают столь неповторимое ощущение отдыха.',

        //Страница Wi-Fi
        wi_fi_title: 'Wi-Fi',
        wi_fi_login: 'Логин',
        wi_fi_password: 'Пароль',

        //Страница Бронирования
        booking_text: 'Введите ваш номер бронирования',
        booking_text_1: 'номер бронирования',
        booking_text_invalid: 'Неверный номер бронирования',
        booking_text_error_connect: 'Ошибка подключения к серверу бронирования',
        booking_text_error_time: 'Превышено количество попыток\nПовторите попытку в',
        booking_text_error_time_2: 'Превышено количество попыток',
        booking_next: 'Войти',
        booking_room: 'Забронировать номер',
        booking_text_2: 'Войти по коду бронирования',
        booking_text_3: 'Ваш Код Бронирования',
        booking_text_4: 'Код бронирования можно получить на ресепшн',

        //Оставить отзыв
        feedback_nav_title: 'Оставить отзыв',
        feedback_title: 'Поделитесь мнением',
        feedback_review_header: 'Каково ваше общее впечатление?',
        feedback_review_header_1: 'Насколько вы удовлетворены качеством обслуживания?',
        feedback_review_header_2: 'Насколько вы удовлетворены качество удобств в номере?',
        feedback_review_header_3: 'Насколько вы удовлетворены качеством уборки номера?',
        feedback_review_header_4: 'Почувствовали ли вы себя желанным гостем в отеле?',
        feedback_review_header_5: 'Возникали ли проблемы во время вашего проживания?',

        feedback_review_tooltip_1: 'Ужасно',
        feedback_review_tooltip_2: 'Плохо',
        feedback_review_tooltip_3: 'Средне',
        feedback_review_tooltip_4: 'Хорошо',
        feedback_review_tooltip_5: 'Отлично',
        feedback_feedback_header: 'Будем рады, если вы расскажите подробнее о ваших впечатлениях о MYS Hotel',
        feedback_comment_textarea_text: 'Добавить комментарий',
        feedback_submit_button_text: 'Отправить отзыв',
        feedback_result_positive_title: 'Спасибо за выбор MYS Hotel для остановки в столице!',
        feedback_result_positive_description:
            'Мы будем ждать вас в гости снова. И в красавце Петербурге тоже – «для наших» дарим скидку 15% на следующие проживания во всей сети MYS Hotel.',
        feedback_result_positive_promocode: 'ПРОМОКОД “VFAMILY”',
        feedback_result_negative_title: 'Спасибо за вашу обратную связь!',
        feedback_result_negative_description: 'Ваше мнение поможет нам усовершенствовать наш сервис и сделать пребывание в MYS Hotel исключительно комфортным.',
        feedback_result_error_title: 'Что-то пошло не так :(',
        feedback_result_error_description: 'Пожалуйста, попробуйте оставить отзыв еще раз.',
        feedback_promocode_copied: 'Промокод скопирован.',

        //профиль
        profile_title: 'Профиль',
        profile_edit: 'редактировать данные',

        profile_edit_title: 'Редактирование профиля',
        profile_edit_photo: 'Изменить фото профиля',
        profile_edit_full_name: 'ФИО',
        profile_edit_full_name_placeholder: 'Введите Ваше ФИО',
        profile_edit_phone: 'Телефон',
        profile_edit_phone_placeholder: 'Введите Ваш номер телефона',
        profile_edit_save_button: 'Сохранить изменения',
        profile_edit_alert_success: 'Данные успешно изменены',
        profile_status: 'STATUS',
        profile_loyalty_text: 'Для участия в программе лояльности авторизуйтесь по номеру телефона.',

        loyalty_to_get_next_status: 'До следующего статуса:',
        loyalty_to_get_next_status_nights: 'ночей',
        loyalty_last_status_congrats: 'Поздравляем! Это последний статус.',
        loyalty_vertical_title: 'MYS Hotel лояльность',
        loyalty_vertical_description: 'Больше живи сегодня - меньше плати завтра.',
        loyalty_vertical_about: 'Подробнее',
        loyalty_detail_title: 'Программа лояльности MYS Hotel',
        loyalty_detail_main_info: 'Основная информация',
        loyalty_detail_main_info_night: 'Столько ночей вы прожили у нас',
        loyalty_detail_main_info_discount: 'Ваша экономия у нас в отеле',
        loyalty_detail_loyalty_title: 'Программа лояльности',
        loyalty_detail_status_1: 'Участник',
        loyalty_detail_status_2: 'Серебряный статус',
        loyalty_detail_status_3: 'Золотой статус',
        loyalty_detail_status_4: 'Платиновый статус',
        loyalty_detail_benefit_1: 'Скидка участника 5%',
        loyalty_detail_benefit_2: 'Бесплатный WI-FI',
        loyalty_detail_benefit_3: 'Доступ к услуге "Mobile check-in"',
        loyalty_detail_benefit_4: 'Скидка на покупку сувенирной продукции 5%',
        loyalty_detail_benefit_5: 'Скидка участника 10%',
        loyalty_detail_benefit_6: 'Поздний выезд 14:00',
        loyalty_detail_benefit_7: 'Скидка на покупку сувенирной продукции 10%',
        loyalty_detail_benefit_8: 'Скидка участника 15%',
        loyalty_detail_benefit_9: 'Ранний заезд 12:00',
        loyalty_detail_benefit_10: 'Поздний выезд 16:00',
        loyalty_detail_benefit_11: 'Чайный сет',
        loyalty_detail_benefit_12: 'Бесплатная гарантия номера',
        loyalty_detail_benefit_13: 'Скидка в ресторане / баре 10%',
        loyalty_detail_benefit_14: 'Скидка на покупку сувенирной продукции 15%',
        loyalty_detail_benefit_15: 'Возможность, раз в год, пригласить друга к программе лояльности, подарив ему сразу статус "Silver"',
        loyalty_detail_benefit_16: 'Скидка участника 20%',
        loyalty_detail_benefit_17: 'Ранний заезд 9:00',
        loyalty_detail_benefit_18: 'Поздний выезд 18:00',
        loyalty_detail_benefit_19: 'Скидка на покупку сувенирной продукции 20%',
        loyalty_detail_benefit_20: '48 часовая гарантия доступности базовой категории номера',
        loyalty_detail_benefit_21: 'Приветственный подарок и открытка GM',
        loyalty_bg_img: 'https://dc.mys-hotel.ru/data/images/loyalty_bg.jpeg',

        residence_history: 'История проживания',
        residence_history_table_header_1: 'Дата заезда',
        residence_history_table_header_2: 'Дата выезда',
        residence_history_table_header_3: 'Отель',
        residence_history_not: 'Ждем вас в гости',

        under_construction: 'Раздел находится в разработке',

        // Отзыв
        your_comment: 'Ваш комментарий',
        send_review: 'Отправить',
        rate_task: 'Оцените заявку',
        improve_us: 'Вы помогаете нам быть лучше для Вас',
        rate_us: 'Поставьте оценку',
        leave_review: 'Оставить отзыв',
    },
    en: {
        language: 'English',

        //служебные
        system_old_version: 'The app is out of date',
        system_old_version_text: 'You have an old version of the application installed. Update?',

        // Главная
        main_1: 'Welcome to',
        main_2: 'MYS Boutique Hotel',
        main_3: 'Sign in with Phone',
        main_4: 'Sign in with Booking Code',
        main_5: 'Sign in with personal data',
        main_6: 'Log in',
        main_7: 'Choose a convenient authorization method for you',
        main_8: 'By logging in to the application, you accept the terms of use and ',
        main_9: 'Book a room',
        main_10: 'privacy policy',

        // Войти по номеру телефона
        phone_1: 'Sign in with Phone',
        phone_2: 'Your Phone number',
        phone_3: 'After pressing the button, you will receive a call.',
        phone_4: "You don't need to answer the call.",
        phone_5: 'Call up',
        phone_6: 'Enter last 4 digits',
        phone_7: 'incoming call numbers',
        phone_8: 'Confirm',
        phone_9: 'Request a new phone number',
        phone_10: 'All numbers to dial are busy, try again by clicking on the "Request a new number" button',
        phone_11: 'Request a new dial-up number by clicking on the "Request a new number" button',
        phone_12: 'You probably did not leave your number',
        phone_13: '<br/>Please ask the reception staff to enter it into the system and try again',
        phone_14: 'Unfortunately, we do not have your number for authorization by phone, try another authorization method or contact the reception',
        phone_15: 'Remaining time to request number again',

        // Войти по фио
        fio_text_1: 'Your information',
        fio_text_2: 'Surname',
        fio_text_3: 'Name',
        fio_text_4: 'Room number',
        fio_text_5: 'The entered data is incorrect. Try a different login method or contact reception',

        //Навигация
        nav_home: 'Мy room',
        nav_chat: 'Chat',
        nav_information: 'Information',
        nav_food: 'Room service',
        nav_food2: 'Pizza',
        nav_shop: 'Services',

        // Страница Управление номером
        my_room_title: 'Room management',
        my_room_open_door: 'Open the door',
        my_room_your_room: 'Your room',
        my_room_conditioner: 'Air conditioning',
        my_manage_warm_floor: 'Warm flor',
        my_room_main_light: 'Main Light',
        my_room_turn_on: 'Turn on',
        my_room_turn_off: 'Turn off',
        my_room_open_off: 'Close',
        my_room_open_on: 'Open',
        my_room_lent: 'LED Strip Light',
        my_room_tv_1: 'TV in the meeting room No. 1',
        my_room_tv_2: 'TV in the meeting room No. 2',
        source_tv: 'Source',
        my_room_humidifier: 'Humidifier',
        humidifier_mode_auto: 'Auto',
        humidifier_mode_1: 'Mode 1',
        humidifier_mode_2: 'Mode 2',
        humidifier_mode_3: 'Mode 3',
        my_room_coffee_machine: 'Coffee maker',
        my_room_tea_machine: 'Kettle',
        my_room_floor_lamp: 'Floor Lamp',
        menage_error: 'Device control error',
        my_bathroom_light: 'Bathroom Light',
        my_room_illumination: 'Backlight',
        my_room_curtains_right: 'Curtains right',
        my_room_curtains_left: 'Curtains left',

        // Страница управления светом
        main_light_east: 'Main Light East',
        main_light_south: 'Main Light South',
        main_hall_1: 'Hall',
        main_hall_2: 'Hall 2',
        administrator_v: 'Administrator',
        zig_zag: 'Zig-zag',
        premises_1: 'Premises 1',
        premises_2: 'Premises 2',

        // страница ошибки 404
        back_404: 'Back',
        not_found_404: 'Not found',
        sorry_404: 'Sorry',
        request_not_found_404: 'Requested content not found.',

        // Страница Чат
        chat_title: 'Chat',
        chat_yes: 'Yes',
        chat_no: 'No',
        chat_add_task: 'Add task',
        chat_message: 'Message',
        chat_kaizen_1: 'Technical maintenance',
        chat_kaizen_1_1: 'Problems with the air-conditioner',
        chat_kaizen_1_2: 'TV problems',
        chat_kaizen_1_3: 'Question about lighting',
        chat_kaizen_1_4: 'Question about plumbing equipment',
        chat_kaizen_1_5: 'Problems with equipment',
        chat_kaizen_2: 'Сleaning',
        chat_kaizen_2_1: 'Clean up my room, please',
        chat_kaizen_2_2: 'Extra towels',
        chat_kaizen_2_3: 'Extra bed linen',
        chat_kaizen_2_4: 'Extra cosmetic kit',
        chat_kaizen_2_5: 'Need an iron and an ironing board',
        chat_kaizen_2_6: 'Clean up my room, please',
        chat_wi_fi: 'Wi-Fi',
        chat_wi_fi_login: 'Login',
        chat_wi_fi_password: 'Password',
        chat_kaizen_created: 'Created',
        chat_kaizen_canceled: 'Canceled',
        chat_kaizen_task_status_1: 'Postponed',
        chat_kaizen_task_status_8: 'Done',
        chat_kaizen_task_status_5: 'Finished',
        chat_kaizen_comments: 'Comments',
        chat_kaizen_error_2: 'Connection error 2',
        chat_kaizen_hello_text: 'Hey! We are sincerely glad that you are with us. Do you have any questions? We will help you.',
        chat_attached_file: 'file',

        //Страница Чат 2
        chat_2_title: 'Correspondence by task',

        //Страница Еда в номер
        food_title: 'Room service',
        food_title2: 'Pizza',
        food_your_order: 'Your order',
        food_order: 'Order',
        food_no_goods_in_category: 'There are no products in this category.',
        food_thank_for_order: 'Thanks for your order!',
        food_checkout: 'Checkout',
        food_opening_hours: 'The bar is open from ',
        food_opening_hours_2: 'to ',
        food_pay: 'Pay',

        //Страница Информация
        information_title: 'Information',
        information_information: 'Information',
        information_hotel_rooms: 'Rooms',
        information_contacts: 'Our contacts',
        information_about: 'Hotel information',
        information_entertainment: 'Entertainment',
        information_events: 'Events',
        information_near_us: 'Near us',
        information_services: 'Services',
        information_lobby_bar: 'Lobby',
        information_attic_bar: 'Attic bar',
        information_spa: 'SPA',
        information_wi_fi: 'Wi-Fi',
        information_settings: 'Settings',
        information_language: 'Language',
        information_language_ru: 'Русский',
        information_language_en: 'English',
        information_log_out: 'Log out',
        information_close: 'Close',
        information_call_reseption: 'Call to Reseption',

        // страница мерч
        shop_title: 'Shop',
        shop_basket_empty: 'Basket is empty',
        shop_amount: 'Amount:',
        shop_buy: 'Buy',
        shop_no_info: 'No data',

        // страница basket-shop
        your_basket: 'Your shopping cart',
        delete_basket: 'Delete',
        my_number_basket: 'My room',
        chat_basket: 'Chat',
        shop_basket: 'Shop',
        send_mess_basket: 'Send an application',

        //Страница Номера
        rooms_title: 'Rooms',
        rooms_room_size: 'Room size:',
        rooms_room_1: 'Standard Single',
        rooms_room_2: 'Standard Double',
        rooms_room_3: 'Standard Twin',
        rooms_room_4: 'Deluxe',

        //Страница Контакты
        contacts_title: 'Our contacts',
        contacts_address_title: 'Address:',
        contacts_address_description: '16, Malyye Kamenshchiki street, Moscow',
        contacts_contacts_title: 'Contacts',
        contacts_contacts_phone: 'Phone:',

        //Страница Мероприятия
        event_title: 'Events',
        event_1_title: 'Intellectual game',
        event_1_location: '2 floor',
        event_1_date: 'March 1, 17:00',
        event_2_title: 'Poker',
        event_2_location: '2 floor',
        event_2_date: 'March 12, 12:00',
        event_3_title: 'Chinese tea party',
        event_3_location: '2 floor',
        event_3_date: 'March 22, 17:00',

        //Страница Об отеле
        about_title: 'Hotel information',
        about_description_title: 'Proptech Solutions \n Digital Concierge',
        about_description_content:
            'A chamber loft-style hotel that offers a new travel format for modern travelers. The hotel has the 4-star category certificate and operates according to Best Western international standards.',

        //Страница Рядом с нами
        near_us_title: 'Near us',
        near_us_1_title: 'Novospassky monastery',
        near_us_1_address: '10, Krestyanskaya square',
        near_us_1_time: '11 min',
        near_us_2_title: 'Historical Museum Bunker 42',
        near_us_2_address: '11, 5th Kotelnichesky lane',
        near_us_2_time: '12 min',
        near_us_3_title: 'Moscow International House of Music',
        near_us_3_address: '52, Kosmodamianskaya nab',
        near_us_3_time: '16 min',

        //Страница Лобби бар
        lobby_title: 'Lobby',
        lobby_location: '2nd floor, reception',
        lobby_time: '24 hours a day',
        lobby_description: "There is a 24-hour reception on the 2nd floor. Just let us know what you want and we'll make it happen in the best possible way.",

        //Страница Lounge-bar на мансарде
        bar_title: 'Lounge bar in the attic',
        bar_location: 'Attic',
        bar_time: 'from 7:30 a.m. to 10 p.m.',
        bar_description:
            'In the morning you can enjoy a delicious breakfast buffet, discuss plans for the day with like-minded people, and spend the evening in a pleasant company in the attic to the music of local bands.',

        //Страница SPA
        spa_title: 'SPA',
        spa_location: 'Attic',
        spa_time: 'soon',
        spa_description:
            'Once you enter our SPA zone on the mansard floor you will emerge yourself into a delightful romantic atmosphere. Just imagine how softly flickering candles, lounge music and exceptional fragrance create such a unique relaxing feeling.',

        //Страница Wi-Fi
        wi_fi_title: 'Wi-Fi',
        wi_fi_login: 'Login',
        wi_fi_password: 'Password',

        //Страница Бронирования
        booking_text: 'Enter your booking number',
        booking_text_1: 'booking number',
        booking_text_invalid: 'Invalid booking number',
        booking_text_error_connect: 'Error connecting to booking server',
        booking_text_error_time: 'Number of attempts exceeded\nPlease try again at',
        booking_text_error_time_2: 'Number of attempts exceeded',
        booking_next: 'Login',
        booking_room: 'Book a room',
        booking_text_2: 'Sign in with Booking Code',
        booking_text_3: 'Your Booking Code',
        booking_text_4: 'You can get the booking code at the reception',

        //Оставить отзыв
        feedback_nav_title: 'Give feedback',
        feedback_title: 'Give feedback',
        feedback_review_header: 'What is your overall impression?',
        feedback_review_header_1: 'How satisfied are you with the quality of service?',
        feedback_review_header_2: 'How satisfied are you with the quality of the room amenities?',
        feedback_review_header_3: 'How satisfied are you with the quality of room cleaning?',
        feedback_review_header_4: 'Did you feel welcome at the hotel?',
        feedback_review_header_5: 'Did any problems arise during your stay?',
        feedback_review_tooltip_1: 'Awful',
        feedback_review_tooltip_2: 'Poor',
        feedback_review_tooltip_3: 'Average',
        feedback_review_tooltip_4: 'Good',
        feedback_review_tooltip_5: 'Great',
        feedback_feedback_header: 'We would be glad if you could tell us more about your impressions of MYS Hotel',
        feedback_comment_textarea_text: 'Add a comment',
        feedback_submit_button_text: 'Send feedback',
        feedback_result_positive_title: 'Thank you for choosing our MYS Hotel for your stay in the capital!',
        feedback_result_positive_description: ' ',
        feedback_result_positive_promocode: 'Promo code “VFAMILY”',
        feedback_result_negative_title: 'Thank you for your feedback!',
        feedback_result_negative_description: ' ',
        feedback_result_error_title: 'Something wrong :(',
        feedback_result_error_description: 'Please try to leave your feedback again.',
        feedback_promocode_copied: 'Copied to clipboard.',

        //профиль
        profile_title: 'Profile',
        profile_edit: 'edit profile',

        profile_edit_title: 'Edit Profile',
        profile_edit_photo: 'Change Profile photo',
        profile_edit_full_name: 'Full name',
        profile_edit_full_name_placeholder: 'Enter your full name',
        profile_edit_phone: 'Phone number',
        profile_edit_phone_placeholder: 'Enter your phone number',
        profile_edit_save_button: 'Save changes',
        profile_edit_alert_success: 'Data changed successfully',
        profile_status: 'STATUS',
        profile_loyalty_text: 'To participate in the loyalty program, log in using your phone number.',

        loyalty_to_get_next_status: 'To get next status:',
        loyalty_to_get_next_status_nights: 'nights',
        loyalty_last_status_congrats: 'Congratulations! This is the latest status.',
        loyalty_vertical_title: 'MYS Hotel Loyalty Program',
        loyalty_vertical_description: 'Live to the fullest and pay less.',
        loyalty_vertical_about: 'About',
        loyalty_detail_title: 'MYS Hotel Loyalty Program',
        loyalty_detail_main_info: 'Overview',
        loyalty_detail_main_info_night: 'nights you stayed in our Hotels',
        loyalty_detail_main_info_discount: 'your savings',
        loyalty_detail_loyalty_title: 'Loyalty program',
        loyalty_detail_status_1: 'Basic Status',
        loyalty_detail_status_2: 'Silver Status',
        loyalty_detail_status_3: 'Gold Status',
        loyalty_detail_status_4: 'Platinum Status',
        loyalty_detail_benefit_1: "5% member's discount",
        loyalty_detail_benefit_2: 'Free Wi-Fi',
        loyalty_detail_benefit_3: 'Mobile Check-in',
        loyalty_detail_benefit_4: '5% off a merchandise purchase',
        loyalty_detail_benefit_5: "10% member's discount",
        loyalty_detail_benefit_6: '2 p.m. Late Check-out',
        loyalty_detail_benefit_7: '10% off a merchandise purchase',
        loyalty_detail_benefit_8: "15% member's discount",
        loyalty_detail_benefit_9: '12 p.m. Early Check-in',
        loyalty_detail_benefit_10: '4 p.m. Late Check-out',
        loyalty_detail_benefit_11: 'Complimentary water, tea and coffee',
        loyalty_detail_benefit_12: 'Guaranteed Room category',
        loyalty_detail_benefit_13: '10% discount at the restaurant/bar',
        loyalty_detail_benefit_14: '15% off a merchandise purchase',
        loyalty_detail_benefit_15: 'Opportunity to invite a friend to the loyalty program, giving him the Silver Status (once a year)',
        loyalty_detail_benefit_16: "20% member's discount",
        loyalty_detail_benefit_17: '9 a.m. Early Check-in',
        loyalty_detail_benefit_18: '6 p.m. Late Check-out',
        loyalty_detail_benefit_19: '20% off a merchandise purchase',
        loyalty_detail_benefit_20: 'Guaranteed 48-hour availability of the basic room category',
        loyalty_detail_benefit_21: 'Welcome gift and GM card',
        loyalty_bg_img: 'https://dc.mys-hotel.ru/data/images/loyalty_bg.jpeg',

        residence_history: 'Residence history',
        residence_history_table_header_1: 'Arrival date',
        residence_history_table_header_2: 'Departure date',
        residence_history_table_header_3: 'Hotel',
        residence_history_not: 'We are waiting for your visit',

        under_construction: 'Under construction',

        // Отзыв
        your_comment: 'Your comment',
        send_review: 'Send',
        rate_task: 'Rate the task',
        improve_us: 'You help us to become better',
        rate_us: 'Rate us',
        leave_review: 'Leave a review',
    },
};
